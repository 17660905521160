import React, {Component} from 'react';
import "../styles/components/Calendar.scss";
import {DefaultHeaderBar} from '../components/Header_bar';
import {getBill, getBranch, getStatus} from '../functions/Api';
import {Redirect} from 'react-router-dom';
import {LavenCalendar} from '../components/Calendar/Calendar';
import {HashSpiner} from '../components/Spiners';
import {CalendarModal, MobileCalendarModal} from '../components/Modals';
import {isMobile} from 'react-device-detect';
import {removeVietnameseTones, findWithAttr} from '../functions/Global';
import socketIOClient from 'socket.io-client';
import {LINK} from '../functions/Global';

const socket = socketIOClient(LINK);

class CalendarView extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            redirect: false,
            link: null,
            branch: null,
            old_events: [],
            modal_visible: false,
            modal_data: null,
            m_modal_visible: false,
            m_modal_data: null,
            modal_date: '',
            autocomplete_data: null
        }

        //GET DON HANG
        getBill((res) => {
            //GET CHI NHANH
            getBranch((branch) => {
                let events = [];
    
                let data = res.filter((data) => {
                    return !data.deleted;
                })

                data.forEach((data) => {
                    const chi_nhanh = findWithAttr(branch,'_id',parseInt(data.chi_nhanh)) > -1 ? branch[findWithAttr(branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '';
                    const gioi_tinh = data.gioi_tinh_be === "0" ? "Bé trai" : "Bé gái"//new Date(data.ngay_chup + ' ' + data.gio_chup)
                
                    let ngay_chup = data.ngay_chup
                    ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null

                    events.push({
                        title: `${data.gio_chup} ${chi_nhanh} - ${data.products[0] === undefined ? "Không có gói chụp" : data.products[0].ten_goi} - ${data.ten_khach_hang} - ${data.so_dien_thoai} - ${gioi_tinh}`, 
                        ngay_chup: `${ngay_chup}`,
                        gio_chup: `${data.gio_chup}`,
                        tinh_trang: data.tinh_trang,
                        branch: chi_nhanh,
                        _id: data._id
                    })
                });
    
                this.setState({
                    events: events,
                    old_events: events,
                    branch: branch
                })
            });
        });

        getStatus((res) => {
            this.setState({
                status: res
            })
        });
    }

    componentDidMount = () => {
        //LISTEN SERVER UPDATE BILL
        socket.on("server-update-bill", data => {
            const chi_nhanh = findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '';
            const gioi_tinh = data.gioi_tinh_be === "0" ? "Bé trai" : "Bé gái"//new Date(data.ngay_chup + ' ' + data.gio_chup)
            let ngay_chup = data.ngay_chup
            ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null
            let events = this.state.events;
            let event_data = {
                title: `${data.gio_chup} ${chi_nhanh} - ${data.products[0] === undefined ? "Không có gói chụp" : data.products[0].ten_goi} - ${data.ten_khach_hang} - ${data.so_dien_thoai} - ${gioi_tinh}`, 
                ngay_chup: `${ngay_chup}`,
                gio_chup: `${data.gio_chup}`,
                tinh_trang: data.tinh_trang,
                branch: chi_nhanh,
                _id: data._id
            }
            
            events[findWithAttr(events, "_id", data._id)] = event_data;

            this.setState({
                events: events
            })
        })

        //LISTEN SERVER CREATE BILL
        socket.on("server-create-bill", data => {
            const chi_nhanh = findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '';
            const gioi_tinh = data.gioi_tinh_be === "0" ? "Bé trai" : "Bé gái"//new Date(data.ngay_chup + ' ' + data.gio_chup)
            let ngay_chup = data.ngay_chup
            ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null
            
            let events = this.state.events;
            let event_data = {
                title: `${data.gio_chup} ${chi_nhanh} - ${data.products[0] === undefined ? "Không có gói chụp" : data.products[0].ten_goi} - ${data.ten_khach_hang} - ${data.so_dien_thoai} - ${gioi_tinh}`, 
                ngay_chup: `${ngay_chup}`,
                gio_chup: `${data.gio_chup}`,
                tinh_trang: data.tinh_trang,
                branch: chi_nhanh,
                _id: data._id
            }
            
            events.push(event_data);

            this.setState({
                events: events
            })
        })

        //LISTEN SERVER REMOVE BILL
        socket.on("server-remove-bill", id => {
            let events = this.state.events;
            events.splice(findWithAttr(events, "_id", id), 1);

            this.setState({
                events: events
            })
        })
    }

    //XU LY CLICK
    handleEventClick = (e) => {
            this.setState({
                modal_visible: true,
                modal_data: e,
                modal_date: e
            })
    }
    
    //THỰC HIỆN LỌC TỪ DROPDOWN
    handleFilter = (branch, photo, support, tinh_trang, month, year, photoshop) => {
        //RELOAD DATA
        getBill((res) => {
            let data = res.sort((a, b) => {
                let ngay_chup_a = a.ngay_chup.split("-");
                ngay_chup_a = ngay_chup_a[0].length !== 2 ? ngay_chup_a[2] + '-' + ngay_chup_a[1] + '-' + ngay_chup_a[0] : ngay_chup_a[0] + '-' +ngay_chup_a[1] + '-' + ngay_chup_a[2];

                let ngay_chup_b = b.ngay_chup.split("-");
                ngay_chup_b = ngay_chup_b[0].length !== 2 ? ngay_chup_b[2] + '-' + ngay_chup_b[1] + '-' + ngay_chup_b[0] : ngay_chup_b[0] + '-' +ngay_chup_b[1] + '-' + ngay_chup_b[2];

                return new Date(ngay_chup_b) - new Date(ngay_chup_a)
            })

          //LỌC DATA
          let filtered_data = data.filter((data, i) => {
              //LỌC THEO CHI NHÁNH
              if(branch !== "all") {
                  if(data.chi_nhanh !== branch) return false;
              }
  
              //LỌC THEO PHOTO
              if(photo !== "all") {
                  if(data.photo !== photo) return false;
              }
  
              //LỌC THEO PHOTOSHOP
              if(photoshop !== "all") {
                  if(data.photoshop !== photoshop) return false;
              }
  
              //LỌC THEO SUPPORT
              if(support !== "all") {
                  if(data.support !== support) return false;
              }

              //LỌC THEO TÌNH TRẠNG
              if(tinh_trang !== "all") {
                  if(data.tinh_trang !== tinh_trang) return false;
              }

              return !data.deleted;
          });

          //GET CHI NHANH
          getBranch((branch) => {
              let events = [];
  
              filtered_data.forEach((data) => {
                  const chi_nhanh = findWithAttr(branch,'_id',parseInt(data.chi_nhanh)) > -1 ? branch[findWithAttr(branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '';
                  const gioi_tinh = data.gioi_tinh_be === "0" ? "Bé trai" : "Bé gái"//new Date(data.ngay_chup + ' ' + data.gio_chup)
                  
                  let ngay_chup = data.ngay_chup
                  ngay_chup = ngay_chup ? ngay_chup.split("-")[0].length == 2 ? ngay_chup : ngay_chup.split("-")[2] + '-' + ngay_chup.split("-")[1] + '-' + ngay_chup.split("-")[0] : null

                  events.push({
                      title: `${data.gio_chup} ${chi_nhanh} - ${data.products[0] === undefined ? "Không có gói chụp" : data.products[0].ten_goi} - ${data.ten_khach_hang} - ${data.so_dien_thoai} - ${gioi_tinh}`, 
                      ngay_chup: `${ngay_chup}`,
                      gio_chup: `${data.gio_chup}`,
                      tinh_trang: data.tinh_trang,
                      branch: chi_nhanh,
                      _id: data._id
                  })
              });
  
              this.setState({
                  events: events,
                  old_events: events,
                  branch: branch
              })
          });
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getBill((res) => {
                let data = res.filter((data) => {
                    return !data.deleted;
                })

                this.setState({
                    bill: data
                })            
            })
        }

        //GET CHI NHANH
        getBranch((branch) => {
            //RELOAD DATA
            getBill((res) => {
                let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})
                data = res.filter((d) => {
                    return !d.deleted;
                })

                let events = [];
    
                //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
                let filtered_data = data.filter((data, i) => {
                    if(removeVietnameseTones(data.ten_khach_hang).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                    if(removeVietnameseTones(data.so_dien_thoai).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                    
                    return false;
                })
    
                filtered_data.forEach((data) => {
                    const chi_nhanh = findWithAttr(branch,'_id',parseInt(data.chi_nhanh)) > -1 ? branch[findWithAttr(branch,'_id',parseInt(data.chi_nhanh))].ten_chi_nhanh : '';
                    const gioi_tinh = data.gioi_tinh_be === "0" ? "Bé trai" : "Bé gái"//new Date(data.ngay_chup + ' ' + data.gio_chup)
                    
                    events.push({
                        title: `${chi_nhanh} - ${data.products[0] === undefined ? "Không có gói chụp" : data.products[0].ten_goi} - ${data.ten_khach_hang} - ${data.so_dien_thoai} - ${gioi_tinh}`, 
                        ngay_chup: `${data.ngay_chup}`,
                        gio_chup: `${data.gio_chup}`,
                        tinh_trang: data.tinh_trang,
                        branch: chi_nhanh,
                        _id: data._id
                    })
                });
    
                console.log(events);
    
                this.setState({
                    autocomplete_data: events
                })           
            })
        })
    }

    render() {
        if(!this.state.branch || !this.state.status) {
            return <div></div>
        }

        if(this.state.redirect) {
            return(
                <Redirect to={`/bill_edit/${this.state.link}`}/>
            )
        }

        return(
            <div>
                <CalendarModal events={this.state.events} date={this.state.modal_date} status={this.state.status} data={this.state.modal_data} visible={this.state.modal_visible} handleCloseModal={() => this.setState({modal_visible: false})}/>
                <DefaultHeaderBar onSearchKeyPress={this.handleSearch} autocomplete_data= {this.state.autocomplete_data} onDropdownFilterBtnClick={this.handleFilter} handleEdit={null} handleCreate={null} create_btn_link={null} handleSave={null} handleRemove={null}  title="Lịch"/>
                {isMobile ? <MobileCalendarModal visible={this.state.m_modal_visible} data={this.state.m_modal_data} handleCloseModal={() => this.setState({m_modal_visible: false})} branch={this.state.branch}/> : null}
                <div className="view">
                    <LavenCalendar events={this.state.events} branch={this.state.branch} onClick={this.handleEventClick} onClickDate={(e) => this.setState({m_modal_data: e, m_modal_visible: true})}/>
                </div>
            </div>
        )
    }
}

export default CalendarView;