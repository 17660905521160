import Axios from 'axios';
import {API} from './Global';

//SERVICES API
//#region
//CREATE 
function createService(data, callback) {
    Axios.post(API + '/create_service', {data: data}).then((res) => {
        return callback(res);
    });
}

//GET
function getServices(callback) {
    Axios.post(API + '/get_services', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE
function getSingleService(id, callback) {
    Axios.post(API + '/get_single_service/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}

//UPDATE
function updateService(data, callback) {
    Axios.post(API + '/update_service', {data:data}).then((res) => {
        return callback(res);
    });
}

//REMOVE
function removeService(id, callback) {
    Axios.post(API + '/remove_service', {id:id}).then((res) => {
        return callback(res);
    });
}

//#endregion

//GLOBAL API
const APIs = {
    get: (action, callback) => {
        Axios.get(`${API}${action}`).then((result) => {
            return callback(result.data);
        })
    },
    post: (action, params, data, callback) => {
        Axios.post(`${API}${action}${params}`,data).then((result) => {
            return callback(result.data);
        })
    }
}

//GOI CHUP API
//#region
//CREATE GOI CHUP
function createGoiChup(data, callback) {
    Axios.post(API + '/create_goi_chup', {data: data}).then((res) => {
        return callback(res);
    });
}

//GET GOI CHUP
function getGoiChup(callback) {
    Axios.post(API + '/get_goi_chup', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE
function getSingleGoiChup(id, callback) {
    Axios.post(API + '/get_single_goi_chup/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}

//UPDATE
function updateGoiChup(data, callback) {
    Axios.post(API + '/update_goi_chup', {data:data}).then((res) => {
        return callback(res);
    });
}

//REMOVE
function removeGoiChup(id, callback) {
    Axios.post(API + '/remove_goi_chup', {id:id}).then((res) => {
        return callback(res);
    });
}

//#endregion

//CHI API
//#region
//CREATE CHI
function createSpending(data, callback) {
    Axios.post(API + '/create_spending', {data: data}).then((res) => {
        return callback(res);
    });
}

//GET CHI
function getSpending(callback) {
    Axios.post(API + '/get_spending', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET CHI
function getSingleSpending(id, callback) {
    Axios.post(API + '/get_single_spending/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}

//UPDATE
function updateSpending(data, callback) {
    Axios.post(API + '/update_spending', {data:data}).then((res) => {
        return callback(res);
    });
}

//REMOVE
function removeSpending(id, callback) {
    Axios.post(API + '/remove_spending', {id:id}).then((res) => {
        return callback(res);
    });
}

//#endregion

//BILL API
//#region
//CREATE BILL

function removeBill(id, callback) {
    Axios.post(API + '/remove_bill', {id: id}).then((res) => {
        return callback(res);
    });
}

function restoreBill(id, callback) {
    Axios.post(API + '/restore_bill', {id: id}).then((res) => {
        return callback(res);
    });
}

function createBill(data, callback) {
    Axios.post(API + '/create_bill', {data: data}).then((res) => {
        return callback(res);
    });
}

//UPDATE BILL
function updateBill(data, callback) {
    Axios.post(API + '/update_bill', {data:data}).then((res) => {
        return callback(res);
    });
}

//GET BILL
function getBill(callback) {
    Axios.post(API + '/get_bill', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE BILL
function getSingleBill(id, callback) {
    Axios.post(API + '/get_single_bill/' + id, {}).then((res) => {
        return callback(res.data.data);
    });
}

//#endregion

//STAFF API
//#region
//REMOVE
function removeStaff(id, callback) {
    Axios.post(API + '/remove_staff', {id:id}).then((res) => {
        return callback(res);
    });
}
//CREATE STAFF

function createStaff(data, callback) {
    Axios.post(API + '/register', {data: data}).then((res) => {
        console.log(res);
        return callback(res);
    });
}

//UPDATE STAFF
function updateStaff(data, id, callback) {
    Axios.post(API + '/update_staff/' + id, {data:data}).then((res) => {
        return callback(res);
    });
}

//GET STAFF
function getStaff(callback) {
    Axios.post(API + '/get_staff', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE STAFF
function getSingleStaff(id, callback) {
    Axios.post(API + '/get_single_staff/' + id, {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE STAFF
function getSingleStaffFromEmail(email, callback) {
    Axios.post(API + '/get_single_staff_from_email/' + email, {}).then((res) => {
        return callback(res.data.data);
    });
}

//#endregion

//PERMISSIONS API
//#region
//CREATE BILL

function createPermission(data, callback) {
    Axios.post(API + '/create_permission', {data: data}).then((res) => {
        return callback(res);
    });
}

//UPDATE BILL
function updatePermission(data, callback) {
    Axios.post(API + '/update_permission', {data:data}).then((res) => {
        return callback(res);
    });
}

//GET BILL
function getPermissions(callback) {
    Axios.post(API + '/get_permissions', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE BILL
function getSinglePermission(id, callback) {
    Axios.post(API + '/get_single_permission/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}

//#endregion

//LOGOUT API
function logout(data, callback) {
    Axios.post(API + '/logout', {data: data}).then((res) => {
        return callback(res);
    });
}

//STATUS API
//#region
//CREATE STATUS
function createStatus(data, callback) {
    Axios.post(API + '/create_status', {data: data}).then((res) => {
        return callback(res);
    });
}

//UPDATE STATUS
function updateStatus(data, callback) {
    Axios.post(API + '/update_status/' + data.id, {ten_tinh_trang:data.ten_tinh_trang, color: data.color}).then((res) => {
        return callback(res);
    });
}

//GET STATUS
function getStatus(callback) {
    Axios.post(API + '/get_status', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE STATUS
function getSingleStatus(id, callback) {
    Axios.post(API + '/get_single_status/' + id, {}).then((res) => {
        return callback(res.data.data);
    });
}
//REMOVE SINGLE STATUS
function removeStatus(id, callback) {
    Axios.post(API + '/remove_status/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}
//#endregion

//BRANCH API
//#region
//CREATE BRANCH

function createBranch(data, callback) {
    Axios.post(API + '/create_branch', {data: data}).then((res) => {
        return callback(res);
    });
}

//UPDATE BRANCH
function updateBranch(data, callback) {
    Axios.post(API + '/update_branch', {data:data}).then((res) => {
        return callback(res);
    });
}

//GET BRANCH
function getBranch(callback) {
    Axios.post(API + '/get_branch', {}).then((res) => {
        return callback(res.data.data);
    });
}

//GET SINGLE BRANCH
function getSingleBranch(id, callback) {
    Axios.post(API + '/get_single_branch/' + id, {}).then((res) => {
        return callback(res.data.data[0]);
    });
}

//#endregion

//SALARY HISTORY API
function getSalaryHistory(callback) {
    Axios.get(API + '/get_salary').then((res) => {
        return callback(res);
    });
}

function updateSalaryHistory(id ,data, callback) {
    Axios.post(API + '/update_salary/'+id, data).then((res) => {
        return callback(res);
    });
}

export {
    createService,
    getServices,
    getSingleService,
    updateService,
    createBill,
    getBill,
    getSingleBill,
    updateBill,
    createGoiChup,
    getSingleGoiChup,
    updateGoiChup,
    getGoiChup,
    getSingleSpending,
    updateSpending,
    getSpending,
    getSingleStaffFromEmail,
    removeSpending,
    createSpending,
    createStaff,
    getStaff,
    getSingleStaff,
    updateStaff,
    createPermission,
    getPermissions,
    getSinglePermission,
    updatePermission,
    createBranch,
    updateBranch,
    getBranch,
    getSingleBranch,
    removeBill,
    removeService,
    removeGoiChup,
    getSalaryHistory,
    removeStaff,
    createStatus,
    updateStatus,
    getStatus,
    getSingleStatus,
    removeStatus,
    logout,
    updateSalaryHistory,
    restoreBill,
    APIs
}