import React, {Component} from 'react';
import {AnalyticsCard} from "../components/Cards";
import {numberWithCommas} from '../functions/Global';
import {getGoiChup, getPermissions, getServices, getStaff, getBranch} from '../functions/Api';
import {AnalyticsTable} from '../components/Table';
import {isMobile} from 'react-device-detect';
import {DefaultHeaderBar} from '../components/Header_bar';
import {LavenDatePicker} from '../components/DatePicker';

import {getBill} from '../functions/Api';
import "../styles/components/Headers.scss";
import "../styles/views/Analytics_view.scss";

class TotalAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goi_chup_data: null,
            services: null,
            staff: null,
            branch: null,
            bill: null,
            data: null,
            total_num: 0,
            total_da_thu_chuyen_khoan: 0,
            total_da_thu_tien_mat: 0,
            total_da_thu: 0,
            total_con_no: 0
        }

        getGoiChup((res) => {
            this.setState ({
                goi_chup_data: res
            })
        });

        getServices((res) => {
            this.setState({
                services: res
            })
        });

        getStaff((res) => {
            this.setState({
                staff: res
            })
        })

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })

        getBill((res) => {
            let data = res;
            data = data.filter((data, i) => {
                return !data.deleted;
            })

            this.setState({
                bill: data
            })
        });
    }

    handleDateTotalFilter() {
        let startDate = this.state.fromDate.split("-");
        startDate = startDate[2] + '-' + startDate[1] + '-' + startDate[0];

        let endDate = this.state.toDate.split("-");
        endDate = endDate[2] + '-' + endDate[1] + '-' + endDate[0];

        let branch = this.refs.branch.value;
        let list = this.refs.list.value;
        let photos = this.refs.photos.value;

        //LỌC THEO NGÀY
        let filter_by_date = this.state.bill.filter((data, i) => {
            let ngay_chup = data.ngay_chup.split("-");
            ngay_chup = ngay_chup[0].length == 2 ? ngay_chup[2] + "-" + ngay_chup[1] + "-" + ngay_chup[0] : data.ngay_chup

            if(startDate > ngay_chup) return false;
            return ngay_chup <= endDate;
        });

        //LỌC THEO CÁC PHẦN TỬ
        let filter_by_attributes = filter_by_date.filter((data, i) => {
            //LỌC CHI NHÁNH
            if(branch !== "all") {
                if(data.chi_nhanh !== branch) return false;
            }

            //LỌC PHOTOS
            if(photos !== "all") {
                if(photos !== data.photo && photos !== data.support) return false;
            }

            //LỌC TRẠNG THÁI CHUYỂN TIỀN
            //if(data.dat_coc_2 == "2" || data.thanh_toan_l1_2 == "2" || data.thanh_toan_l2_2 == "2") return false;

            return true;
        });

        let total_bill = 0;
        let total_da_thu_ck = 0;
        let total_da_thu_tm = 0;
        let total_con_no = 0;

        filter_by_attributes.map((data, i) => {
            //LỌC VÀ TÍNH TỔNG ĐƠN THEO GÓI CHỤP HOẶC DỊCH VỤ
            if(list !== "all") {
                data.products.map((product, i) => {
                    if(list === "goi_chup") {
                        let services_filter = this.state.goi_chup_data.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                        if(services_filter > -1) total_bill += ((parseInt(product.gia) * parseInt(product.so_luong)) - (product.giam_gia ? parseInt(product.giam_gia) : 0));
                    }else if(list === "services") {
                        let goi_chup_filter = this.state.services.map(function(e) { return e.ten_goi; }).indexOf(product.ten_goi);
                        if(goi_chup_filter > -1) total_bill += ((parseInt(product.gia) * parseInt(product.so_luong)) - (product.giam_gia ? parseInt(product.giam_gia) : 0));
                    }
                });
            }else {
                total_bill += data.tong;
            }

            total_con_no += data.con_no_1;

            //TÍNH TỔNG ĐÃ THU
            if(data.dat_coc_2 == "0") {
                total_da_thu_ck += data.dat_coc_1;
            }else {
                total_da_thu_tm += data.dat_coc_1;
            }

            if(data.thanh_toan_l1_2 == "0") {
                total_da_thu_ck += data.thanh_toan_l1_1;
            }else {
                total_da_thu_tm += data.thanh_toan_l1_1;
            }

            if(data.thanh_toan_l2_2 == "0") {
                total_da_thu_ck += data.thanh_toan_l2_1;
            }else{
                total_da_thu_tm += data.thanh_toan_l2_1;
            }

            if(data.thanh_toan_l3_2 == "0") {
                total_da_thu_ck += data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0;
            }else {
                total_da_thu_tm += data.thanh_toan_l3_1 ? data.thanh_toan_l3_1 : 0;
            }
        });
    
        this.setState({
            total_num: numberWithCommas(total_bill),
            data: filter_by_attributes,
            total_da_thu_chuyen_khoan: numberWithCommas(total_da_thu_ck),
            total_da_thu_tien_mat: numberWithCommas(total_da_thu_tm),
            total_da_thu: numberWithCommas(total_da_thu_ck + total_da_thu_tm),
            total_con_no: numberWithCommas(total_con_no)
        })
    }

    handleChangeFromDate = (date) => {
        this.setState({
          fromDate: date
        });
    }

    handleChangeToDate = (date) => {
        this.setState({
          toDate: date
        });
    }

    render() {
        if(!this.state.branch || !this.state.staff || !this.state.services || !this.state.goi_chup_data) {
            return(<div></div>)
        }
        return(
            <div className="analytics_view">
                {isMobile ? <DefaultHeaderBar handleRemove={null} handleSave={null} handleEdit={null} create_btn_link={null} handleCreate={null}/> : null}
                <div className="toolbar">
                    {isMobile ? null : <h1 className="view_header" style={{marginRight: "24px", marginLeft: "24px"}}>Tổng Quát</h1>}
                    <div className="group filter">
                        <div className="box">
                            <select ref="branch">
                                <option value="all" selected>Tất cả</option>
                                {this.state.branch.map((data, i) => {
                                    return(
                                        <option value={data._id}>{data.ten_chi_nhanh}</option>
                                    )
                                })}
                            </select>
                            <select ref="list">
                                <option value="all" selected>Tất cả</option>
                                <option value="goi_chup">Gói chụp</option>
                                <option value="services">Dịch vụ</option>
                            </select>
                            <select ref="photos">
                                <option value="all" selected>Tất cả</option>
                                {this.state.staff.map((data, i) => {
                                    return(
                                        <option value={data._id}>{data.ho_ten}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <h1 className="view_header">Từ</h1>
                        <div className="box">
                            <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.fromDate} ref="fromDate" className="textbox" onChange={this.handleChangeFromDate}/>
                        </div>
                        <h1 className="view_header">Đến</h1>
                        <div className="box">
                            <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.toDate} ref="toDate" className="textbox" onChange={this.handleChangeToDate}/>
                        </div>
                        <button className="btn blue" onClick={this.handleDateTotalFilter.bind(this)}>LỌC</button>
                    </div>
                </div>
                <div className="overview">
                    <AnalyticsCard type="tong" value={this.state.total_num}></AnalyticsCard>
                    <AnalyticsCard type="da_thu" value={this.state.total_da_thu} chuyen_khoan_num={this.state.total_da_thu_chuyen_khoan} tien_mat_num={this.state.total_da_thu_tien_mat}></AnalyticsCard>
                    <AnalyticsCard type="con_no" value={this.state.total_con_no}></AnalyticsCard>
                </div>
                <AnalyticsTable data={this.state.data}/>
            </div>
        )
    }
}

export default TotalAnalytics;