import React, {Component} from 'react';
import {updateSpending, APIs, createSpending, removeSpending, updateStatus,createStatus, removeService,removeGoiChup,updateBranch,getBranch,createBranch,getPermissions, createGoiChup, updateGoiChup, createService, updateService, createPermission, updatePermission, getGoiChup, getSingleGoiChup, getServices, removeStatus} from '../functions/Api';
import {GrClose} from 'react-icons/gr';
import {Link} from 'react-router-dom';
import {findWithAttr} from '../functions/Global';
import {LavenDatePicker} from '../components/DatePicker';
import {HashSpiner} from './Spiners';

import "../styles/components/Modals.scss";

class TakePhotoPackageModal extends Component {
    handleSave = () => {
        let data = {
            ten_goi: this.refs.ten_goi.value,
            gia: this.refs.gia.value,
        }

        this.props.onLoading();
        
        createGoiChup(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    render() {
        if(this.props.loading) {
            return(
                <div className="modal">
                     <div className="form_container">
                        <HashSpiner/>
                        <a>LOADING...</a>
                     </div>
                </div>
            )
        }

        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <div className="form_container">
                     <h1>Thêm Gói Chụp</h1>
                     <div className="box">
                         <span className="title">Tên gói</span>
                         <input type="text" className="textbox" ref="ten_goi"/>
                     </div>
                     <div className="box">
                         <span className="title">Giá</span>
                         <input type="text" className="textbox" ref="gia"/>
                     </div>

                     <div className="column">
                         <button className="btn" onClick={this.props.handleCloseModal}>Hủy</button>
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}

class TakePhotoPackageEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        let data = {
            _id: this.props.edit_id,
            ten_goi: this.refs.ten_goi.value,
            gia: this.refs.gia.value,
        }
        
        updateGoiChup(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleRemove = () => {
        removeGoiChup(this.props.edit_id, (res) => {
            this.props.handleCloseModal();
        });
    }

    render() {
        if(!this.props.data || !this.state.perms){
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Gói Chụp</h1>
                     <div className="box">
                         <span className="title">Tên gói</span>
                         <input type="text" className="textbox" ref="ten_goi" defaultValue={this.props.data.ten_goi}/>
                     </div>
                     <div className="box">
                         <span className="title">Giá</span>
                         <input type="text" className="textbox" ref="gia" defaultValue={this.props.data.gia}/>
                     </div>

                     <div className="column">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.goi_chup[3] == "1" ? <button className="btn" onClick={this.handleRemove}>Xóa</button> : null }
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.goi_chup[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null }
                     </div>
                </div>
            </div>
        )
    }
}

class SpendingModal extends Component {

    handleSave = () => {
        if(this.refs.type.value == "2") {
            alert("Vui lòng chọn loại");
        }else{
            let data = {
                noi_dung: this.refs.noi_dung.value,
                so_tien: this.refs.so_tien.value,
                date: new Date(),
                user: localStorage.getItem('ho_ten'),
                user_id: localStorage.getItem('user_id'),
                type: this.refs.type.value
            }
            
            createSpending(data, (res) => {
                this.props.handleCloseModal();
            });
        }
    }

    render() {
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <div className="form_container">
                     <h1>Thêm Mục Đã Chi</h1>
                     <div className="box">
                         <span className="title">Nội dung chi</span>
                         <input type="text" className="textbox" ref="noi_dung"/>
                     </div>
                     <div className="box">
                         <span className="title">Số tiền đã chi</span>
                         <input type="number" className="textbox" ref="so_tien"/>
                     </div>
                     <div className="box">
                         <span className="title">Loại</span>
                         <select className="textbox"  ref="type" defaultValue="2">
                             <option value="2" disabled></option>
                             <option value="0">Chuyển Khoản</option>
                             <option value="1">Tiền Mặt</option>
                         </select>
                     </div>

                     <div className="column">
                         <button className="btn" onClick={this.props.handleCloseModal}>Hủy</button>
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}


class SpendingEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        let data = {
            _id: this.props.edit_id,
            noi_dung: this.refs.noi_dung.value,
            so_tien: this.refs.so_tien.value,
            type: this.refs.type.value,
            date: this.props.date_edit
        }
        
        updateSpending(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleRemove = () => {
        removeSpending(this.props.edit_id, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleChangeDate = (date) => {
        this.props.onChangeDate(date);
    }

    render() {
        if(!this.props.data || !this.state.perms){
            return(<div></div>)
        }

        let date_edit = this.props.date_edit.split("-");
        date_edit = date_edit[0].length == 2 ? date_edit[0] + '-' + date_edit[1] + '-' + date_edit[2] : date_edit[2] + '-' + date_edit[1] + '-' + date_edit[0]

        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container" style={{width: "475px"}}>
                     <h1>Sửa Mục Đã Chi</h1>
                     <div className="box">
                         <span className="title">Người nhập</span>
                         <input type="text" className="textbox" ref="nguoi_nhap" defaultValue={this.props.data.user} disabled/>
                     </div>
                     <div className="box">
                         <span className="title">Nội dung chi</span>
                         <input type="text" className="textbox" ref="noi_dung" defaultValue={this.props.data.noi_dung} disabled={this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[2] == "1" ? null : 'disabled'}/>
                     </div>
                     <div className="box">
                         <span className="title">Số tiền đã chi</span>
                         <input type="number" className="textbox" ref="so_tien" defaultValue={this.props.data.so_tien} disabled={this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[2] == "1" ? null : 'disabled'}/>
                     </div>
                     <div className="box calendar">
                         <span className="title">Ngày chi</span>
                         {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[2] == "1" ? 
                            <LavenDatePicker format={'DD-MM-YYYY'}  date={date_edit} ref="date"  className="textbox"  onChange={this.handleChangeDate}/> :
                            <input className="textbox" type="text" defaultValue={date_edit} disabled/>
                        }
                     </div>
                     <div className="box">
                         <span className="title">Loại</span>
                         <select className="textbox" ref="type" defaultValue={this.props.data.type} disabled={this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[2] == "1" ? null : 'disabled'}>
                             <option value="0">Chuyển Khoản</option>
                             <option value="1">Tiền Mặt</option>
                         </select>
                     </div>

                     <div className="column">
                        {this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[3] == "1" ? <button className="btn" onClick={this.handleRemove}>Xóa</button> : null }
                        {this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null }
                     </div>
                </div>
            </div>
        )
    }
}

class ServicesModal extends Component {
    handleSave = () => {
        let data = {
            ten_goi: this.refs.ten_dich_vu.value,
            gia: this.refs.gia.value,
        }
        
        createService(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    render() {
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Thêm Dịch Vụ</h1>
                     <div className="box">
                         <span className="title">Tên dịch vụ</span>
                         <input type="text" ref="ten_dich_vu" className="textbox"/>
                     </div>
                     <div className="box">
                         <span className="title">Giá</span>
                         <input type="text" ref="gia" className="textbox"/>
                     </div>

                     <div className="column">
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}

class ServicesEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        let data = {
            _id: this.props.edit_id,
            ten_goi: this.refs.ten_dich_vu.value,
            gia: this.refs.gia.value,
        }
        
        updateService(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleRemove = () => {
        removeService(this.props.edit_id, (res) => {
            this.props.handleCloseModal();
        });
    }

    render() {
        if(!this.props.data || !this.state.perms){
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}> 
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Dịch Vụ</h1>
                     <div className="box">
                         <span className="title">Tên dịch vụ</span>
                         <input type="text" className="textbox" ref="ten_dich_vu" defaultValue={this.props.data.ten_goi}/>
                     </div>
                     <div className="box">
                         <span className="title">Giá</span>
                         <input type="text" className="textbox" ref="gia" defaultValue={this.props.data.gia}/>
                     </div>

                     <div className="column">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.services[3] == "1" ? <button className="btn" onClick={this.handleRemove}>Xóa</button> : null }
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.services[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null }
                     </div>
                </div>
            </div>
        )
    }
}

class PermissionModal extends Component {
    handleSave = () => {
        let data = {
            ten_nhom: this.refs.ten_nhom.value,
            permisisons: {
                "cham_cong" : ["1","1","1","1"],"spending_analytics" : ["1","1","1","1"],"spending" : ["1","1","1","1"], "trash" : ["1","1","1","1"],"bill":["1","1","0","0"],"calendar":["1","1","0","0"],"customers":["1","1","1","1"],"goi_chup":["1","1","1","1"],"services":["1","1","1","1"],"total_analytics":["1","1","1","1"],"realtime_analytics":["1","1","1","1"],"services_analytics":["1","1","1","1"],"staff":["1","1","1","1"],"permisisons":["1","1","1","1"],"branch":["1","1","1","1"],"status":["1","1","1","1"],"staff_salary":["1","1","1","1"],"backup":["1","1","1","1"]
            }
        }

        //Nếu tên nhóm là admin thì không cho lưu
        if(!data.ten_nhom.toLowerCase() == 'admin') {
            createPermission(data, (res) => {
                this.props.onCreateNew();
            });
        }else{
            alert("Tên không hợp lệ, vui lòng chọn tên khác!");
        }
    }

    render() {
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <div className="form_container">
                     <h1>Thêm Nhóm Quyền</h1>
                     <div className="box">
                         <span className="title">Tên nhóm</span>
                         <input type="text" className="textbox" ref="ten_nhom"/>
                     </div>

                     <div className="column">
                         <button className="btn" onClick={this.props.handleCloseModal}>Hủy</button>
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}

class PermissionEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        getPermissions((result) => {
            let data = result;
            data[this.props.tab].ten_nhom = this.refs.ten_nhom.value;
    
            updatePermission(data, (res) => {
                this.props.handleSave();
            });
        });
    }

    handleRemove = () => {
        APIs.post('/remove_permission', '', {id: this.props._id}, res => {
            this.props.handleRemove();
        })
    }

    render() {
        if(!this.state.perms){
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Phân Quyền</h1>
                     <div className="box">
                         <span className="title">Tên nhóm</span>
                         <input type="text" className="textbox" ref="ten_nhom" defaultValue={this.props.ten_nhom}/>
                     </div>

                     {this.props.ten_nhom.toLowerCase() !== "admin" ? //Tên nhóm không phải admin thì cho chỉnh sửa
                     <div className="column">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.permisisons[3] == "1" ? <button className="btn red" onClick={this.handleRemove}>Xóa</button> : null }
                        <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div> : <div className="column">
                        <button className="btn red" onClick={this.props.handleCloseModal}>Hủy</button>
                     </div>}
                </div>
            </div>
        )
    }
}

class BillServicesModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goi_chup: null
        }

        getGoiChup((res) => {
            let goi_chup = res;
            
            getServices((result) => {
                let data = result.concat(goi_chup);

                this.setState ({
                        goi_chup: data
                    })
            })
        });
    }

    handleSave = () => {
        let data = this.state.goi_chup.filter((data, i) => {
            return data._id == this.refs.ten_san_pham.value
        });

        let giam_gia = parseInt(this.refs.giam_gia.value.toString().replace(/\.|\,/g,''))

        let new_data = {
            chiet_khau: 0,
            so_luong: this.refs.so_luong.value,
            _id: data[0]._id,
            ten_goi: data[0].ten_goi,
            giam_gia: giam_gia,
            gia: data[0].gia
        }

        this.props.onSave(new_data);
    }

    render() {
        if(!this.state.goi_chup) {
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <div className="form_container">
                     <h1>Thêm Sản Phẩm</h1>
                    
                     <div className="box" style={{width: "215px"}}>
                        <span className="title">Tên sản phẩm</span>
                        <select ref="ten_san_pham">
                            {this.state.goi_chup.map((data, i) => {
                                return <option value={data._id}>{data.ten_goi}</option>  
                            })}
                        </select>
                    </div>

                     <div className="box">
                         <span className="title">Số lượng</span>
                         <input type="number" className="textbox" ref="so_luong" min="0" defaultValue="1"/>
                     </div>

                    <div className="box">
                        <span className="title">Giảm giá</span>
                        <input type="number" className="textbox" ref="giam_gia" min="0" defaultValue={0}/>
                    </div>

                     <div className="column">
                         <button className="btn" onClick={this.props.onClose}>Hủy</button>
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}

class BillServicesEditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            goi_chup: null,
            data: null,
            giam_gia: null,
            so_luong: null,
            _id: null,
            id: null
        }

        getGoiChup((res) => {
            let goi_chup = res;
            
            getServices((result) => {
                let data = result.concat(goi_chup);

                this.setState ({
                        goi_chup: data
                    })
            })
        });
    }

    handleSave = () => {
        let data = this.state.goi_chup.filter((data, i) => {
            return data._id == this.refs.ten_san_pham.value
        });

        let giam_gia = parseInt(this.refs.giam_gia.value.toString().replace(/\.|\,/g,''))

        let new_data = {
            chiet_khau: 0,
            so_luong: this.refs.so_luong.value,
            _id: data[0]._id,
            ten_goi: data[0].ten_goi,
            giam_gia: giam_gia,
            gia: data[0].gia
        }
        
        this.props.onSave(new_data, this.state.id);
    }

    handleRemove = (id, data) => {
        this.props.onRemove(id, data)
    }

    render() {
        const chuc_vu = findWithAttr(this.props.perms, '_id', localStorage.getItem('chuc_vu'));

        if(!this.state.goi_chup) {
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.onClose}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Sản Phẩm</h1>
                    
                     <div className="box" style={{width: "215px"}}>
                        <span className="title">Tên sản phẩm</span>
                        <select ref="ten_san_pham"  onChange={(e) => this.setState({_id: e.target.value})} value={this.state._id} disabled={this.props.perms[chuc_vu].permisisons.bill[2] != "1"}>
                            {this.state.goi_chup.map((data, i) => {
                                return <option value={data._id}>{data.ten_goi}</option>  
                            })}
                        </select>
                    </div>

                     <div className="box">
                         <span className="title">Số lượng</span>
                         <input type="number" min="0" onChange={(e) => this.setState({so_luong: e.target.value})} className="textbox" ref="so_luong" value={this.state.so_luong} disabled={this.props.perms[chuc_vu].permisisons.bill[2] != "1"}/>
                     </div>

                    <div className="box">
                        <span className="title">Giảm giá</span>
                        <input type="number" className="textbox" onChange={(e) => this.setState({giam_gia: e.target.value})} ref="giam_gia" min="0" value={this.state.giam_gia}/>
                    </div>

                     <div className="column">
                         {this.props.perms[chuc_vu].permisisons.bill[3] == "1" ? <button className="btn" onClick={() => this.handleRemove(this.state.id, this.props.data)}>Xóa</button> : null}
                         {this.props.perms[chuc_vu].permisisons.bill[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null}
                     </div>
                </div>
            </div>
        )
    }
}

class StatusModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: 'blue'
        }
    }

    handleSave = () => {
        let data = {
            ten_tinh_trang: this.refs.ten_tinh_trang.value,
            color: this.state.color
        }

        createStatus(data, (res) => {});

        this.props.handleCloseModal();
    }

    handleChangeColor = (color) => {
        this.setState({
            color: color
        })
    }

    render() {
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>

                <div className="form_container">
                     <h1>Thêm Tình Trạng</h1>

                     <div className="box">
                         <span className="title">Tên tình trạng</span>
                         <input type="text" className="textbox" ref="ten_tinh_trang"/>
                     </div>

                     <div className="box">
                         <span className="title">Chọn màu</span>
                         <div className="colors_picker_container">
                            <div className={this.state.color === 'blue' ? "color blue active" : "color blue"} onClick={() => this.handleChangeColor('blue')}></div>
                            <div className={this.state.color === 'purple' ? "color purple active" : "color purple"}onClick={() => this.handleChangeColor('purple')}></div>
                            <div className={this.state.color === 'orange' ? "color orange active" : "color orange"} onClick={() => this.handleChangeColor('orange')}></div>
                            <div className={this.state.color === 'yellow' ? "color yellow active" : "color yellow"} onClick={() => this.handleChangeColor('yellow')}></div>
                            <div className={this.state.color === 'red' ? "color red active" : "color red"} onClick={() => this.handleChangeColor('red')}></div>
                            <div className={this.state.color === 'brown' ? "color brown active" : "color brown"}onClick={() => this.handleChangeColor('brown')}></div>
                            <div className={this.state.color === 'salmon' ? "color salmon active" : "color salmon"}onClick={() => this.handleChangeColor('salmon')}></div>
                            <div className={this.state.color === 'violet' ? "color violet active" : "color violet"}onClick={() => this.handleChangeColor('violet')}></div>
                            <div className={this.state.color === 'wheat' ? "color wheat active" : "color wheat"}onClick={() => this.handleChangeColor('wheat')}></div>
                            <div className={this.state.color === 'black' ? "color black active" : "color black"} onClick={() => this.handleChangeColor('black')}></div>
                            <div className={this.state.color === 'gray' ? "color gray active" : "color gray"} onClick={() => this.handleChangeColor('gray')}></div>
                         </div>
                     </div>

                     <div className="column">
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}


class StatusEditModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            color: ''
        }
    }

    handleSave = () => {
        updateStatus({ten_tinh_trang: this.refs.ten_tinh_trang.value, id: this.props.data._id, color: this.state.color}, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleRemove = () => {
        removeStatus(this.props.data._id, (res) => {});
        this.props.handleCloseModal();
    }

    handleChangeColor = (color) => {
        this.setState({
            color: color
        })
    }

    render() {
        if(!this.props.data){
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Tình Trạng</h1>
                     <div className="box">
                         <span className="title">Tên tình trạng</span>
                         <input type="text" className="textbox" ref="ten_tinh_trang" defaultValue={this.props.data.ten_tinh_trang} disabled={this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.status[2] == "1" ? null : 'disabled'}/>
                     </div>

                    <div className="box">
                        <span className="title">Chọn màu</span>
                        <div className="colors_picker_container">
                           <div className={this.state.color === 'blue' ? "color blue active" : "color blue"} onClick={() => this.handleChangeColor('blue')}></div>
                           <div className={this.state.color === 'purple' ? "color purple active" : "color purple"}onClick={() => this.handleChangeColor('purple')}></div>
                           <div className={this.state.color === 'orange' ? "color orange active" : "color orange"} onClick={() => this.handleChangeColor('orange')}></div>
                           <div className={this.state.color === 'yellow' ? "color yellow active" : "color yellow"} onClick={() => this.handleChangeColor('yellow')}></div>
                           <div className={this.state.color === 'red' ? "color red active" : "color red"} onClick={() => this.handleChangeColor('red')}></div>
                            <div className={this.state.color === 'brown' ? "color brown active" : "color brown"}onClick={() => this.handleChangeColor('brown')}></div>
                            <div className={this.state.color === 'salmon' ? "color salmon active" : "color salmon"}onClick={() => this.handleChangeColor('salmon')}></div>
                            <div className={this.state.color === 'violet' ? "color violet active" : "color violet"}onClick={() => this.handleChangeColor('violet')}></div>
                            <div className={this.state.color === 'wheat' ? "color wheat active" : "color wheat"}onClick={() => this.handleChangeColor('wheat')}></div>
                           <div className={this.state.color === 'black' ? "color black active" : "color black"} onClick={() => this.handleChangeColor('black')}></div>
                           <div className={this.state.color === 'gray' ? "color gray active" : "color gray"} onClick={() => this.handleChangeColor('gray')}></div>
                        </div>
                    </div>

                     <div className="column">
                        {this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.status[3] == "1" ? <button className="btn red" onClick={this.handleRemove}>Xóa</button> : null}
                        {this.props.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.status[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null}
                     </div>
                </div>
            </div>
        )
    }
}

class BranchModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: ''
        }
    }

    handleSave = () => {
        let data = {
            ten_chi_nhanh: this.refs.ten_chi_nhanh.value,
            dia_chi: this.refs.dia_chi.value,
            color: this.state.color
        }

        createBranch(data, (res) => {
            this.props.handleCloseModal();
        });
    }

    handleChangeColor = (color) => {
        this.setState({
            color: color
        })
    }

    render() {
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>

                <div className="form_container">
                     <h1>Thêm Chi Nhánh</h1>

                     <div className="box">
                         <span className="title">Tên chi nhánh</span>
                         <input type="text" className="textbox" ref="ten_chi_nhanh"/>
                     </div>

                     <div className="box textarea" style={{width: "234px"}}>
                         <span className="title">Địa chỉ</span>
                         <textarea className="textbox" ref="dia_chi"></textarea>
                     </div>

                    <div className="box">
                        <span className="title">Chọn màu</span>
                        <div className="colors_picker_container">
                           <div className={this.state.color === 'blue' ? "color blue active" : "color blue"} onClick={() => this.handleChangeColor('blue')}></div>
                           <div className={this.state.color === 'purple' ? "color purple active" : "color purple"}onClick={() => this.handleChangeColor('purple')}></div>
                           <div className={this.state.color === 'orange' ? "color orange active" : "color orange"} onClick={() => this.handleChangeColor('orange')}></div>
                           <div className={this.state.color === 'yellow' ? "color yellow active" : "color yellow"} onClick={() => this.handleChangeColor('yellow')}></div>
                           <div className={this.state.color === 'red' ? "color red active" : "color red"} onClick={() => this.handleChangeColor('red')}></div>
                           <div className={this.state.color === 'black' ? "color black active" : "color black"} onClick={() => this.handleChangeColor('black')}></div>
                           <div className={this.state.color === 'gray' ? "color gray active" : "color gray"} onClick={() => this.handleChangeColor('gray')}></div>
                        </div>
                    </div>

                     <div className="column">
                         <button className="btn blue" onClick={this.handleSave}>Lưu</button>
                     </div>
                </div>
            </div>
        )
    }
}

class BranchEditModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            color: '',
            perms: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        getBranch((result) => {
            let data = result;
            data[this.props.edit_id].ten_chi_nhanh = this.refs.ten_chi_nhanh.value;
            data[this.props.edit_id].dia_chi = this.refs.dia_chi.value;
            data[this.props.edit_id].color = this.state.color;
    
            //console.log(data);

            updateBranch(data, (res) => {
                this.props.handleSave();
            });
        });
    }

    handleRemove = () => {
        getBranch((result) => {
            let data = result.filter((data, i) => {return data._id !== result[this.props.edit_id]._id});
            
            updateBranch(data, (res) => {
                this.props.handleSave();
            });
        });
    }

    handleChangeColor = (color) => {
        this.setState({
            color: color
        })
    }

    render() {
        if(!this.props.data || !this.state.perms){
            return(<div></div>)
        }
        return(
            <div className="modal" style={{display: `${this.props.is_open === "true" ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                     <h1>Sửa Chi Nhánh</h1>
                     <div className="box">
                         <span className="title">Tên chi nhánh</span>
                         <input type="text" className="textbox" ref="ten_chi_nhanh" defaultValue={this.props.data.ten_chi_nhanh}/>
                     </div>

                    <div className="box textarea" style={{width: "234px"}}>
                        <span className="title">Địa chỉ</span>
                        <textarea className="textbox" ref="dia_chi">{this.props.data.dia_chi}</textarea>
                    </div>

                    <div className="box">
                        <span className="title">Chọn màu</span>
                        <div className="colors_picker_container">
                           <div className={this.state.color === 'blue' ? "color blue active" : "color blue"} onClick={() => this.handleChangeColor('blue')}></div>
                           <div className={this.state.color === 'purple' ? "color purple active" : "color purple"}onClick={() => this.handleChangeColor('purple')}></div>
                           <div className={this.state.color === 'orange' ? "color orange active" : "color orange"} onClick={() => this.handleChangeColor('orange')}></div>
                           <div className={this.state.color === 'yellow' ? "color yellow active" : "color yellow"} onClick={() => this.handleChangeColor('yellow')}></div>
                           <div className={this.state.color === 'red' ? "color red active" : "color red"} onClick={() => this.handleChangeColor('red')}></div>
                           <div className={this.state.color === 'black' ? "color black active" : "color black"} onClick={() => this.handleChangeColor('black')}></div>
                           <div className={this.state.color === 'gray' ? "color gray active" : "color gray"} onClick={() => this.handleChangeColor('gray')}></div>
                        </div>
                    </div>

                     <div className="column">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.branch[3] == "1" ? <button className="btn red" onClick={this.handleRemove}>Xóa</button> : null }
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.branch[2] == "1" ? <button className="btn blue" onClick={this.handleSave}>Lưu</button> : null }
                     </div>
                </div>
            </div>
        )
    }
}

class CalendarModal extends Component {
    render() {
        if(this.props.data == undefined) {
            return <div></div>
        }

        let data = this.props.data;
        let ngay_chup = data.ngay_chup.split("-");

        return(      
            <div className="modal m_calendar" style={{display: `${this.props.visible ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                    <h1>{data.title}</h1>
                    <span>Ngày chụp: {ngay_chup[0]} tháng {ngay_chup[1]} năm {ngay_chup[2]}</span>
                    <span>Giờ chụp: {data.gio_chup}</span>
                    <div class="column">
                        <button class="btn" onClick={this.props.handleCloseModal}>Đóng</button>
                        <Link to={`/bill_edit/${data._id}`}><button class="btn blue">Sửa</button></Link>
                    </div>
                </div>
            </div>
        )
    }
}

class MobileCalendarModal extends Component {
    render() {
        if(this.props.data == undefined) {
            return <div></div>
        }

        return(      
            <div className="modal m_calendar" style={{display: `${this.props.visible ? "flex" : "none"}`}}>
                <button className="close_btn" onClick={this.props.handleCloseModal}>
                    <GrClose className="icon"/>
                </button>
                <div className="form_container">
                    <h1>Xem Lịch</h1>
                    {this.props.data.map((data, i) => {
                        return(
                            <Link to={`/bill_edit/${data._id}`} className={`event_card ${findWithAttr(this.props.branch,'ten_chi_nhanh',data.branch) > -1 ? this.props.branch[findWithAttr(this.props.branch,'ten_chi_nhanh',data.branch)].color : ''}`}>
                                <span className="date">{data.ngay_chup} {data.gio_chup}</span>
                                <p className="content">{data.title}</p>
                            </Link>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export {
    StatusModal,
    TakePhotoPackageModal,
    TakePhotoPackageEditModal,
    ServicesModal,
    ServicesEditModal,
    PermissionModal,
    BillServicesModal,
    BillServicesEditModal,
    PermissionEditModal,
    BranchModal,
    BranchEditModal,
    StatusEditModal,
    CalendarModal,
    MobileCalendarModal,
    SpendingModal,
    SpendingEditModal
}