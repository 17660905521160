import React, {Component} from 'react';
import {getStaff} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {ChamCongTable} from '../components/Table';
import {removeVietnameseTones} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class ChamCong extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }

        getStaff((res) => {
          this.setState ({
            data: res
          })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getStaff((res) => {
                this.setState({
                    data: res
                })            
            })
        }

        //RELOAD DATA
        getStaff((res) => {
            let data = res.reverse()
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.email).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                if(removeVietnameseTones(data.ho_ten).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                
                return false;
            })
  
            this.setState({
                data: filtered_data
            })            
        })
    }

    updateData = () => {
        getStaff((res) => {
        this.setState ({
            data: res
        })
      })
    }

    render() {
        if(!this.state.data) {
            return(<div className="load_view"><HashSpiner/></div>)
        }

        return(
            <div>
                <DefaultHeaderBar handleEdit={null} onGoBack={() => this.props.history.goBack()} handleCreate={null} create_btn_link={null} onSearchKeyPress={this.handleSearch} handleSave={null} handleRemove={null} title="Quản Lý Chấm Công" back_link="/settings"/>
                <ChamCongTable data={this.state.data}/>
            </div>
        )
    }
}

export default ChamCong;