import React, {Component} from 'react';
import {DefaultHeaderBar} from '../components/Header_bar';
import {StatusTable} from '../components/Table';
import {StatusModal, StatusEditModal} from '../components/Modals';
import {getStatus, getSingleStatus, getPermissions} from '../functions/Api';
import { findWithAttr} from '../functions/Global';

class Status extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_modal_open: "false",
            is_edit_modal_open :"false",
            data: null,
            edit_data: null,
            edit_id: null,
            perms: null
        }

        getStatus((res) => {
            this.setState ({
                data: res
            })
        });
        
        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleOpenCreateModal = () => {
        this.setState ({
            is_modal_open: "true"
        })
    }

    handleOpenEditModal = (id, i) => {
        getSingleStatus(id, (res) => {
            this.setState ({
                edit_data: res,
                edit_id: i,
                is_edit_modal_open: "true"
            })

            this.refs.status_edit_modal.handleChangeColor(res.color);
        });
    }

    handleCloseModal = () => {
        getStatus((res) => {
            this.setState ({
                data: res,
                is_modal_open: "false",
                is_edit_modal_open :"false",
                edit_data: null
            })
        });
    }

    render() {
        if(!this.state.data || !this.state.perms) {
            return(<div></div>);
        }
        return(
            <div>
                <DefaultHeaderBar  onGoBack={() => this.props.history.goBack()} title="Tình Trạng"  handleCreate={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.status[1] == "1" ? this.handleOpenCreateModal : null}/>
                <StatusTable data={this.state.data} onClick={this.handleOpenEditModal}/>
                <StatusEditModal perms={this.state.perms} ref="status_edit_modal"  edit_id={this.state.edit_id} data={this.state.edit_data} is_open={this.state.is_edit_modal_open}  handleCloseModal={this.handleCloseModal}/>
                <StatusModal is_open={this.state.is_modal_open} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default Status;