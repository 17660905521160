import React, {Component} from 'react';
import "./styles.scss";
import {IoChevronBack, IoChevronForward} from 'react-icons/io5';

//Lấy ngày trong tháng
function getDaysInMonth(month, year, callback) {
    let months = parseInt(month) - 1;
    var date = new Date(year, months, 1);
    var days = [];
    while (date.getMonth() === months) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return callback(days);
  }

//Xây dựng lịch tháng
function calendar(month, year, callback) {
    let daysOfMonth = []
    getDaysInMonth(month, year, (res) => {
        //Thêm số 0 vào các ngày trống
        for(var i = 1; i < res[0].getDay(); i++) {
            daysOfMonth.push(0)
        }

        //Nếu ngày đầu tiên rơi vào CN
        if(res[0].getDay() === 0) daysOfMonth = [0,0,0,0,0,0];

        //Thêm ngày vào mảng
        for(const date of res) {
            daysOfMonth.push(date.getDate());
        }

        //Thêm số 0 vào các ngày trống ở cuối
        for(var i = res[res.length - 1].getDay();i <= 6; i++) {
            daysOfMonth.push(0)
        }
    
        return callback({date: daysOfMonth, month: month, year: year});
    })
}

let year_data = [];

for(var i = (new Date().getFullYear() - 20); i <= (new Date().getFullYear() + 20); i++) {
    year_data.push(i);
}

export class LavenDatePicker extends Component {
    constructor(props) {
        super(props);

        let format = this.props.format;
        let date = this.props.date ? this.props.date.split("-") : null;
        const hasDate = format.indexOf("DD") > -1 ? true : false;
        const hasMonth = format.indexOf("MM") > -1 ? true : false;
        const hasYear = format.indexOf("YYYY") > -1 ? true : false;
        
        format = this.props.format.split("-");
        let dates = hasDate ? date ? parseInt(date[format.indexOf("DD")]) : -1 : -1;
        let month = hasMonth ? date ? parseInt(date[format.indexOf("MM")]) : -1 : -1;
        let year = hasYear ? date ? parseInt(date[format.indexOf("YYYY")]) : -1 : -1;

        this.state = {
            is_visible: false,
            calendar_data: null,
            date: dates !== -1 ? dates : new Date().getDate(),
            month: month !== -1 ? month : new Date().getMonth() + 1,
            year: year !== -1 ? year : new Date().getFullYear(),
            focus_date: dates,
            focus_month: month,
            focus_year: year,
            year_data: year_data,
            tab: hasDate ? 0 : hasMonth ? 1 : 2,
            month_data: [1,2,3,4,5,6,7,8,9,10,11,12]
        }

        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.handleUpdateCalendar(this.state.month, this.state.year)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleUpdateCalendar = (month, year) => {
        calendar(month, year, (result) => {
            this.setState({
                calendar_data: result.date,
                month: result.month,
                year: result.year
            })
        });
    }

    //NEXT & PREV MONTH
    handleNavigate = (type) => {
        if(type === 'next') {
            if(this.state.month === 12) {
                this.setState({
                    month: 1,
                    focus_month: 1,
                    year: parseInt(this.state.year) + 1,
                    focus_year: parseInt(this.state.year) + 1
                })

                this.handleUpdateCalendar(1, parseInt(this.state.year) + 1);
                this.handleChange(this.state.date, 1, parseInt(this.state.year) + 1);
            }else{
                this.setState({
                    month: parseInt(this.state.month) + 1,
                    focus_month: parseInt(this.state.month) + 1
                })

                this.handleUpdateCalendar(parseInt(this.state.month) + 1, this.state.year);
                this.handleChange(this.state.date, parseInt(this.state.month) + 1, this.state.year);
            }
        }else {
            if(this.state.month === 1) {
                this.setState({
                    month: 12,
                    focus_month: 12,
                    year: parseInt(this.state.year) - 1,
                    focus_year: parseInt(this.state.year) - 1
                })

                this.handleUpdateCalendar(12, parseInt(this.state.year) - 1);
                this.handleChange(this.state.date, 12, parseInt(this.state.year) - 1);
            }else{
                this.setState({
                    month: parseInt(this.state.month) - 1,
                    focus_month: parseInt(this.state.month) - 1
                })

                this.handleUpdateCalendar(parseInt(this.state.month) - 1, this.state.year);
                this.handleChange(this.state.date, parseInt(this.state.month) - 1, this.state.year);
            }
        }
    } 

    //FOCUS DATE
    handleFocusDate = (date) => {
        if(date !== 0) {
            this.setState({
                focus_date: date,
                date: date,
                is_visible: false
            })
            this.handleChange(date, this.state.month, this.state.year);
        }
    }

    //FOCUS MONTH
    handleFocusMonth = (month) => {
        this.setState({
            focus_month: month,
            month: month,
            is_visible: false
        })

        this.handleUpdateCalendar(month, this.state.year);
        this.handleChange(this.state.date, month, this.state.year);
    }

    //FOCUS YEAR
    handleFocusYear = (year) => {
        this.setState({
            focus_year: year,
            year: year,
            is_visible: false
        })

        this.handleUpdateCalendar(this.state.month, year);
        this.handleChange(this.state.date, this.state.month, year);
    }

    //ONCHANGE
    handleChange = (date, month, year) => {
        let date_str = this.props.format;
        date_str = date_str.replace(/DD/g, date <= 9 ? '0' + date : date);
        date_str = date_str.replace(/MM/g, month <= 9 ? '0' + month : month);
        date_str = date_str.replace(/YYYY/g, year);
    
        this.props.onChange(date_str);
    }

    //CLICK OUTSIDE POPUP
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                is_visible: false
            })
        }
    }

    render() {
        if(!this.state.calendar_data || !this.state.year_data) {
            return <div></div>
        }

        const format = this.props.format;
        const hasDate = format.indexOf("DD") > -1 ? true : false;
        const hasMonth = format.indexOf("MM") > -1 ? true : false;
        const hasYear = format.indexOf("YYYY") > -1 ? true : false;
        
        return(
            <div className="laven date_picker" style={this.props.style}>
                <input className={this.props.className} value={this.props.date} onClick={() => this.setState({is_visible: true})} readOnly disabled={this.props.disabled}/>
                
                <div className="date_picker_popup" style={{display: this.state.is_visible ? 'block' : 'none'}} ref={this.wrapperRef}>
                    <div className="control_container">
                        <button className="control prev" onClick={() => this.handleNavigate('prev')}><IoChevronBack className="icon"/></button>
                        {hasDate ? <span className={this.state.tab == 0 ? "date active" : "date"} onClick={() => this.setState({tab: 0})}>{this.state.date}</span> : null}
                        {hasMonth ? <span className={this.state.tab == 1 ? "date active" : "date"} onClick={() => this.setState({tab: 1})}>Tháng {this.state.month}</span> : null}
                        {hasYear ? <span className={this.state.tab == 2 ? "date active" : "date"} onClick={() => this.setState({tab: 2})}>{this.state.year}</span> : null }
                        <button className="control next" onClick={() => this.handleNavigate('next')}><IoChevronForward className="icon"/></button>
                    </div>

                    {this.state.tab == 0 ?
                        <div className="calendar_container">
                            <div className="calendar_header">
                                <div className="date">Th 2</div>
                                <div className="date">Th 3</div>
                                <div className="date">Th 4</div>
                                <div className="date">Th 5</div>
                                <div className="date">Th 6</div>
                                <div className="date">Th 7</div>
                                <div className="date">CN</div>
                            </div>
                            <div className="calendar_dates">
                                {this.state.calendar_data.map((data, i) => {
                                    return <div className={this.state.focus_date == data ? "date focused" : new Date().getDate() == data && (new Date().getMonth() + 1) == this.state.month && new Date().getFullYear() == this.state.year ? "date active" : "date"} onClick={() => this.handleFocusDate(data)} key={i}>{data !== 0 ? data : ''}</div>
                                })}
                            </div>
                        </div> : null}

                    {this.state.tab == 1 ? 
                        <div className="month_container">
                            <div className="calendar_months">
                                {this.state.month_data.map((month) => {
                                    return <div className={this.state.focus_month == month ? "month focused" : (new Date().getMonth() + 1) == month ? "month active" : "month"} onClick={() => this.handleFocusMonth(month)}>{month}</div>
                                })}
                            </div>
                        </div> : null}

                    {this.state.tab == 2 ?
                        <div className="year_container">
                            <div className="calendar_years">
                                {this.state.year_data.map((year) => {
                                    return <div className={this.state.focus_year == year ? "year focused" : (new Date().getFullYear()) == year ? "year active" : "year"} onClick={() => this.handleFocusYear(year)}>{year}</div>
                                })}
                            </div> 
                        </div> : null}
                </div>
            </div>
        )
    }
}