//Lấy ngày trong tháng
function getDaysInMonth(month, year, callback) {
    let months = parseInt(month) - 1;
    var date = new Date(year, months, 1);
    var days = [];
    while (date.getMonth() === months) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return callback(days);
  }

//Xây dựng lịch tháng
function calendar(month, year, callback) {
    let daysOfMonth = []
    getDaysInMonth(month, year, (res) => {
        //Thêm số 0 vào các ngày trống
        for(var i = 1; i < res[0].getDay(); i++) {
            daysOfMonth.push(0)
        }

        //Nếu ngày đầu tiên rơi vào CN
        if(res[0].getDay() === 0) daysOfMonth = [0,0,0,0,0,0];

        //Thêm ngày vào mảng
        for(const date of res) {
            daysOfMonth.push(date.getDate());
        }

        //Thêm số 0 vào các ngày trống ở cuối
        for(var i = res[res.length - 1].getDay();i <= 6; i++) {
            daysOfMonth.push(0)
        }
    
        return callback({date: daysOfMonth, month: month, year: year});
    })
}

export {
    calendar
}