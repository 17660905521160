import React, { Component, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import {APP_VERSION_LABEL} from './functions/Global';

//VIEWS
import Home from './views/Home';
import BillEdit from './views/Bill_Edit';
import BillPrint from './views/BillPrint';
import CalendarView from './views/Calendar';
import Customers from './views/Customers';
import TakePhotoPackages from './views/Take_Photo_Packages';
import Services from './views/Services';
import Products from './views/Products';
import TotalAnalytics from './views/TotalAnalytics';
import RealtimeAnalytics from './views/RealtimeAnalytics';
import Settings from './views/Settings';
import Staff from './views/Staff';
import StaffEdit from './views/Staff_Edit';
import Permission from './views/Permission';
import Branch from './views/Branch';
import Login from './views/Login';
import Status from './views/Status';
import Logout from './views/Logout';
import {Redirect} from 'react-router-dom';
import Salary from './views/Salary';
import SalaryEdit from './views/SalaryEdit';
import Backup from './views/Backup';
import Trash from './views/Trash';
import Spending from './views/Spending';
import SideBar from './components/Side_bar';
import ChamCong from './views/ChamCong';
import ChamCongList from './views/ChamCongList';
import ChamCongManagement from './views/ChamCongManagement';
import Personnel from './views/Personnel';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    localStorage.getItem('authed')
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

const App = () => {
  const [auth] = useState(true);

  return (
    <div>
      {localStorage.getItem('authed') ? <SideBar/> : null}
      {auth ? <Main/> : null}
      <Version/>
    </div>
  );
}

const Version = () => {
  return(
    <div className="version">
      <a href="https://lavenstudio.tk/" target="_blank">{APP_VERSION_LABEL}</a>
    </div>
  )
}

const Main = (props) => {
  return (
    <main>
      <Switch>
        <Route exact path='/login' component={Login}/>
        <Route exact path='/logout' component={Logout}/>
        <Route exact path='/backup' component={Backup}/>
        <Route exact path='/backup/:type' component={Backup}/>
        <PrivateRoute exact path='/' component={Home}/>
        <PrivateRoute exact path='/cham_cong' component={ChamCong}/>
        <PrivateRoute exact path='/cham_cong_list' component={ChamCongList}/>
        <PrivateRoute exact path='/cham_cong_edit/:id' component={ChamCongManagement}/>
        <PrivateRoute exact path='/spending' component={Spending}/>
        <PrivateRoute exact path='/bill_edit/:id' component={BillEdit}/>
        <PrivateRoute exact path='/bill_edit/:id/:date' component={BillEdit}/>
        <PrivateRoute exact path='/bill_restore/:id' component={BillEdit}/>
        <PrivateRoute exact path='/bill_print/:id' component={BillPrint}/>
        <PrivateRoute exact path='/calendar' component={CalendarView}/>
        <PrivateRoute exact path='/customers' component={Customers}/>
        <PrivateRoute exact path='/take_picture_packages' component={TakePhotoPackages}/>
        <PrivateRoute exact path='/services' component = {Services}/>
        <PrivateRoute exact path='/products' component={Products}/>
        <PrivateRoute exact path='/total_revenue' component={TotalAnalytics}/>
        <PrivateRoute exact path='/realtime_revenue' component={RealtimeAnalytics}/>
        <PrivateRoute exact path='/trash' component={Trash}/>
        <PrivateRoute exact path='/settings' component={Settings}/>
        <PrivateRoute exact path='/settings/personnel' component={Personnel}/>
        <PrivateRoute exact path='/staff' component={Staff}/>
        <PrivateRoute exact path='/staff_edit/:id' component={StaffEdit}/>
        <PrivateRoute exact path='/permission' component={Permission}/>
        <PrivateRoute exact path='/branch' component={Branch}/>
        <PrivateRoute exact path='/status' component={Status}/>
        <PrivateRoute exact path='/staff_salary' component={Salary}/>
        <PrivateRoute exact path='/staff_salary_edit/:id' component={SalaryEdit}/>
      </Switch>
    </main>
  )
}

export default App;