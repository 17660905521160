import React, {Component} from 'react';
import {AnalyticsCard, AnalyticsChartCard, AnalyticsChartCard2} from "../components/Cards";
import {numberWithCommas, findWithAttr} from '../functions/Global';
import {AnalyticsTable, SpendingTableAnalytics} from '../components/Table';
import {isMobile} from 'react-device-detect';
import {DefaultHeaderBar} from '../components/Header_bar';
import {LavenDatePicker} from '../components/DatePicker';
import {HashSpiner} from '../components/Spiners';

import {getBill, getBranch, getStaff, getSpending, APIs} from '../functions/Api';
import "../styles/components/Headers.scss";
import "../styles/views/Analytics_view.scss";

class RealtimeAnalytics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bill: null,
            total_num: 0,
            total_da_thu_chuyen_khoan: 0,
            total_da_thu_tien_mat: 0,
            total_spending: 0,
            total_spending_chuyen_khoan: 0,
            total_spending_tien_mat: 0,
            start_date: null,
            end_date: null,
            data: null,
            spending_data: null,
            branch: null,
            staff: null,
            spending: null,
            total_con_giu: 0,
            total_con_giu_chuyen_khoan: 0,
            total_con_giu_tien_mat: 0,
            is_spending: true,
            perms: null
        }


        getBill((res) => {
            let data = res;
            data = data.filter((data, i) => {
                return !data.deleted;
            })

            this.setState({
                bill: data
            })
        });

        getSpending((res) => {
            this.setState({
                spending: res
            })
        })

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })

        getStaff((res) => {
            this.setState({
                staff: res
            })
        })

        APIs.post('/get_permissions', '', {}, res => {
            this.setState({
                perms: res.data
            })
        })
    }

    async handleDateTotalFilter() {
        let startDate = this.state.fromDate.split("-");
        startDate = startDate[2] + '-' + startDate[1] + '-' + startDate[0];

        let endDate = this.state.toDate.split("-");
        endDate = endDate[2] + '-' + endDate[1] + '-' + endDate[0];
        
        let branch = this.refs.branch.value;
        let staff = this.refs.staff.value;
        let bill = this.state.bill;
        let spending = this.state.spending;

        //CHUYỂN ĐỊNH DANGJ NGÀY
        const transDate = (value) => {
            try {
                let date = value.split("-");
                date = date[0] + '-' + (date[1] <= 9 ? '0' + date[1] : date[1]) + '-' + date[2];

                return date;
            }catch(e) {

            }
        }

        //LỌC THEO CÁC PHẦN TỪ CHI
        spending = spending.filter((data, i) => {
            let date = data.date.split("T")[0];

            //LỌC TÊN NHÂN VIÊN
            if(staff !== "all") {
                if(data.user == staff && startDate <= date && date <= endDate) {
                    return true;
                }else {
                    return false;
                }
            }

            return startDate <= date && date <= endDate
        })

        //LỌC THEO CÁC PHẦN TỬ
        bill = await bill.filter((data, i) => {
            let thanh_toan_l1_ho_ten = data.thanh_toan_l1_1_des ? data.thanh_toan_l1_1_des.split("-")[1].replace(" ", "") : "";
            let thanh_toan_l2_ho_ten = data.thanh_toan_l2_1_des ? data.thanh_toan_l2_1_des.split("-")[1].replace(" ", "") : "";
            let thanh_toan_l3_ho_ten = data.thanh_toan_l3_1_des ? data.thanh_toan_l3_1_des.split("-")[1].replace(" ", "") : "";
            let dat_coc_ho_ten = data.dat_coc_1_des ? data.dat_coc_1_des.split("-")[1].replace(" ", "") : "";
            let dat_coc_time = transDate(data.dat_coc_1_time);
            let thanh_toan_l1_time = transDate(data.thanh_toan_l1_1_time);
            let thanh_toan_l2_time = transDate(data.thanh_toan_l2_1_time);
            let thanh_toan_l3_time = transDate(data.thanh_toan_l3_1_time);

            //LỌC CHI NHÁNH
            if(branch !== "all") {
                if(data.chi_nhanh !== branch) return false;
            }

            //LỌC TÊN NHÂN VIÊN
            if(staff !== "all") {
                if(dat_coc_ho_ten == staff && startDate <= dat_coc_time && dat_coc_time <= endDate) {
                    return true;
                }else if(thanh_toan_l1_ho_ten == staff && startDate <= thanh_toan_l1_time && thanh_toan_l1_time <= endDate) {
                    return true;
                }else if(thanh_toan_l2_ho_ten == staff && startDate <= thanh_toan_l2_time && thanh_toan_l2_time <= endDate) {
                    return true;
                }else if(thanh_toan_l3_ho_ten == staff && startDate <= thanh_toan_l3_time && thanh_toan_l3_time <= endDate) {
                    return true;
                }else{
                    return false;
                }
            } 

            //LỌC TRẠNG THÁI CHUYỂN TIỀN
            //if(data.dat_coc_2 == "2" || data.thanh_toan_l1_2 == "2" || data.thanh_toan_l2_2 == "2" || data.thanh_toan_l3_3 == "2") return false;

            return true;
        });

        //LỌC THEO NGÀY ĐẶT CỌC
        let filter_by_dat_coc = await bill.filter((data, i) => {
            let dat_coc_ho_ten = data.dat_coc_1_des ? data.dat_coc_1_des.split("-")[1].replace(" ", "") : "";
            let date = transDate(data.dat_coc_1_time);
            bill[i].thu_trong_ngay = 0;
            
            if(staff !== "all" && dat_coc_ho_ten == staff){
                if(startDate <= date && date <= endDate) {
                    bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.dat_coc_1 : data.dat_coc_1 ;
                    return true;
                };
            }

            if(staff == "all" && startDate <= date && date <= endDate) {
                bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.dat_coc_1 : data.dat_coc_1 ;
                return true;
            };

            return false;
        });

        //LỌC THEO NGÀY THU LẦN 1
        let filter_by_thanh_toan_l1 = await bill.filter((data, i) => {
            let thanh_toan_l1_ho_ten = data.thanh_toan_l1_1_des ? data.thanh_toan_l1_1_des.split("-")[1].replace(" ", "") : "";
            let date = transDate(data.thanh_toan_l1_1_time);
            
            if(staff !== "all" && thanh_toan_l1_ho_ten == staff){
                if(startDate <= date && date <= endDate) {
                    bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l1_1 : data.thanh_toan_l1_1 ;
                    return true;
                };
            }

            if(staff == "all" && startDate <= date && date <= endDate) {
                bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l1_1 : data.thanh_toan_l1_1 ;
                return true;
            };

            return false;
        });

        //LỌC THEO NGÀY THU LẦN 2 
        let filter_by_thanh_toan_l2 = await bill.filter((data, i) => {
            let thanh_toan_l2_ho_ten = data.thanh_toan_l2_1_des ? data.thanh_toan_l2_1_des.split("-")[1].replace(" ", "") : "";
            let date = transDate(data.thanh_toan_l2_1_time);
            
            if(staff !== "all" && thanh_toan_l2_ho_ten == staff){
                if(startDate <= date && date <= endDate) {
                    bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l2_1 : data.thanh_toan_l2_1 ;
                    return true;
                };
            }

            if(staff == "all" && startDate <= date && date <= endDate) {
                bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l2_1 : data.thanh_toan_l2_1 ;
                return true;
            };

            return false;
        });

        //LỌC THEO NGÀY THU LẦN 3
        let filter_by_thanh_toan_l3 = await bill.filter((data, i) => {
            try {
                let date = transDate(data.thanh_toan_l3_1_time);
                let thanh_toan_l3_ho_ten = data.thanh_toan_l3_1_des ? data.thanh_toan_l3_1_des.split("-")[1].replace(" ", "") : "";
            
                if(staff !== "all" && thanh_toan_l3_ho_ten == staff){
                    bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l3_1 : data.thanh_toan_l3_1 ;
                    return true;
                };
    
                if(staff == "all" && startDate <= date && date <= endDate) {
                    bill[i].thu_trong_ngay = bill[i].thu_trong_ngay ? bill[i].thu_trong_ngay + data.thanh_toan_l3_1 : data.thanh_toan_l3_1   ;
                    return true;
                };
    
                return false;
            }catch(e) {
            }
        });

        //LỌC KẾT QUẢ TRẢ RA BẢNG
        let filter_all = await bill.filter((data, i) => {
            try {
                if(startDate > transDate(data.dat_coc_1_time)) {
                    if(startDate > transDate(data.thanh_toan_l1_1_time)) {    
                        if(startDate > transDate(data.thanh_toan_l2_1_time)) {
                            if(startDate > transDate(data.thanh_toan_l3_1_time)) {
                                return false;
                            }else if(transDate(data.thanh_toan_l3_1_time) <= endDate) return true;
                        }else if(transDate(data.thanh_toan_l2_1_time) <= endDate) return true;
                    }else if(transDate(data.thanh_toan_l1_1_time) <= endDate) return true;
                }else if(transDate(data.thanh_toan_l1_1_time) <= endDate) return true;
            }catch(e) {
                return false;
            }
        })

        let total_bill = 0;
        let total_da_thu_ck = 0;
        let total_da_thu_tm = 0;
        let total_spending = 0;
        let total_spending_ck = 0;
        let total_spending_tm = 0;
        let total_con_giu = 0;
        let total_con_giu_ck = 0;
        let total_con_giu_tm = 0;

        //Tính tổng chi
        for(const data of spending) {
            total_spending += data.so_tien;

            if(data.type == 0) {
                total_spending_ck += data.so_tien
            }else{
                total_spending_tm += data.so_tien
            }
        }

        //Tính tổng đặt cọc
        for(const data of filter_by_dat_coc) {
            total_bill += data.dat_coc_1;

            if(data.dat_coc_2 == 0) {
                total_da_thu_ck += data.dat_coc_1
            }else{
                total_da_thu_tm += data.dat_coc_1
            }
        }

        //Tính tổng ngày thu lần 1
        for(const data of filter_by_thanh_toan_l1) {
            total_bill += data.thanh_toan_l1_1

            if(data.thanh_toan_l1_2 == 0) {
                total_da_thu_ck += data.thanh_toan_l1_1
            }else{
                total_da_thu_tm += data.thanh_toan_l1_1
            }
        }

        //Tính tổng ngày thu lần 2
        for(const data of filter_by_thanh_toan_l2) {
            total_bill += data.thanh_toan_l2_1

            if(data.thanh_toan_l2_2 == 0) {
                total_da_thu_ck += data.thanh_toan_l2_1
            }else{
                total_da_thu_tm += data.thanh_toan_l2_1
            }
        }

        //Tính tổng ngày thu lần 3
        for(const data of filter_by_thanh_toan_l3) {
            total_bill += data.thanh_toan_l3_1

            if(data.thanh_toan_l3_2 == 0) {
                total_da_thu_ck += data.thanh_toan_l3_1
            }else{
                total_da_thu_tm += data.thanh_toan_l3_1
            }
        }
        //Tính còn giữ
        total_con_giu = total_bill - total_spending;
        total_con_giu_ck = total_da_thu_ck - total_spending_ck;
        total_con_giu_tm = total_da_thu_tm - total_spending_tm;

        this.setState({
            total_num: numberWithCommas(total_bill),
            total_da_thu_chuyen_khoan: numberWithCommas(total_da_thu_ck),
            total_da_thu_tien_mat: numberWithCommas(total_da_thu_tm),
            total_con_giu: numberWithCommas(total_con_giu),
            total_con_giu_chuyen_khoan: numberWithCommas(total_con_giu_ck),
            total_con_giu_tien_mat: numberWithCommas(total_con_giu_tm),
            total_spending: numberWithCommas(total_spending),
            total_spending_chuyen_khoan: numberWithCommas(total_spending_ck),
            total_spending_tien_mat: numberWithCommas(total_spending_tm),
            start_date: startDate,
            end_date: endDate,
            data: filter_all,
            spending_data: spending
        })
    }

    handleChangeFromDate = (date) => {
        this.setState({
          fromDate: date
        });
    }

    handleChangeToDate = (date) => {
        this.setState({
          toDate: date
        });
    }

    render() {
        if(!this.state.bill ||   !this.state.branch || !this.state.staff || !this.state.spending || !this.state.perms) {
            return(<HashSpiner/>)
        }

        let is_spending = this.state.is_spending;
        const perms = this.state.perms;

        return(
            <div className="analytics_view">
                {isMobile ? <DefaultHeaderBar handleRemove={null} handleSave={null} handleEdit={null} create_btn_link={null} handleCreate={null}/> : null}
                <div className="toolbar">
                    {isMobile ? null : <h1 className="view_header" style={{marginRight: "24px", marginLeft: "24px"}}>Tổng Quát</h1>}
                    <div className="group filter">
                        <div className="box">
                            <select ref="branch" disabled={perms[findWithAttr(perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending_analytics[2] === "1" ? '' : 'disabled'}>
                                <option value="all" selected>Tất cả</option>
                                {this.state.branch.map((data, i) => {
                                    return(
                                        <option value={data._id}>{data.ten_chi_nhanh}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className="box">
                            <select ref="staff" defaultValue={perms[findWithAttr(perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending_analytics[2] === "1" ? 'all' : localStorage.getItem('ho_ten')} disabled={perms[findWithAttr(perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending_analytics[2] === "1" ? '' : 'disabled'}>
                                <option value="all" selected={perms[findWithAttr(perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.spending_analytics[2] === "1" ? 'selected' : ''}>Tất cả</option>
                                {this.state.staff.map((data, i) => {
                                    return(
                                        <option value={data.ho_ten}>{data.ho_ten}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <h1 className="view_header">Từ</h1>
                        <div className="box">
                            <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.fromDate} ref="fromDate" className="textbox" onChange={this.handleChangeFromDate}/>
                        </div>
                        <h1 className="view_header">Đến</h1>
                        <div className="box">
                            <LavenDatePicker format={'DD-MM-YYYY'} date={this.state.toDate} ref="toDate" className="textbox" onChange={this.handleChangeToDate}/>
                        </div>
                        <button className="btn blue" onClick={this.handleDateTotalFilter.bind(this)}>LỌC</button>
                    </div>
                </div>
                <div className="overview">
                    <AnalyticsCard onClick={() => this.setState({is_spending: false})} type="da_thu" start_date={this.state.start_date} end_date={this.state.end_date} value={this.state.total_num} chuyen_khoan_num={this.state.total_da_thu_chuyen_khoan} tien_mat_num={this.state.total_da_thu_tien_mat}></AnalyticsCard>
                    <AnalyticsCard onClick={() => this.setState({is_spending: true})} type="da_chi" start_date={this.state.start_date} end_date={this.state.end_date} value={this.state.total_spending} chuyen_khoan_num={this.state.total_spending_chuyen_khoan} tien_mat_num={this.state.total_spending_tien_mat}></AnalyticsCard>
                    <AnalyticsCard type="con_giu" start_date={this.state.start_date} end_date={this.state.end_date} value={this.state.total_con_giu} chuyen_khoan_num={this.state.total_con_giu_chuyen_khoan} tien_mat_num={this.state.total_con_giu_tien_mat}></AnalyticsCard>
                </div>
                    {is_spending ? <SpendingTableAnalytics data={this.state.spending_data}/> : <AnalyticsTable data={this.state.data}/>}
            </div>
        )
    }
}

export default RealtimeAnalytics;