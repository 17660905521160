import React, {Component} from 'react';
import {PermissionModal, PermissionEditModal} from '../components/Modals';
import {DefaultHeaderBar} from '../components/Header_bar';
import {getPermissions,updatePermission} from '../functions/Api';
import { findWithAttr} from '../functions/Global';

class TabBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tab_active: this.props.tab
        }
    }

    handleChangeTab = (tab) => {
        this.setState({
            tab_active: tab
        });

        this.props.onChangeTab(tab);
    }

    render() {

        return(
            <div className="tab_bar">
                {this.props.data.map((data , i) => {
                    return(
                        <div key={i} onClick={() => this.handleChangeTab(i)} className={this.state.tab_active === i ? "tab active" : "tab"}>
                            <span className="tab_title">{data.ten_nhom}</span>
                        </div>
                    )
                })}
            </div>
        )
    }
}

class TabContentContainer extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            empty: [0,0,0,0]
        }
    }
    render() {
        let data = this.props.data;
        return(
            <div className="tab_content_container">
                <div className="tab_content_header_container">
                    <h1 className="tab_content_header">Danh sách chức năng:</h1>
                    <div className="tab_header_right_item_container">
                        <span className="tab_header_item_title">Truy cập</span>
                        <span className="tab_header_item_title">Thêm</span>
                        <span className="tab_header_item_title">Chỉnh sửa</span>
                        <span className="tab_header_item_title">Xóa</span>
                    </div>
                </div>
                <div className="tab_content">
                    <div className="tab_side_bar">
                        <span className="tab_side_bar_title">Công Việc</span>
                            <span className="tab_side_bar_item">Đơn Hàng</span>
                            <span className="tab_side_bar_item">Lịch</span>
                            <span className="tab_side_bar_item">Khách Hàng</span>
                            <span className="tab_side_bar_item">Chi</span>

                        <span className="tab_side_bar_title">Sản Phẩm</span>
                            <span className="tab_side_bar_item">Gói Chụp</span>
                            <span className="tab_side_bar_item">Dịch Vụ</span>
                        
                        <span className="tab_side_bar_title">Báo Cáo</span>
                            <span className="tab_side_bar_item">Báo Cáo Doanh Thu</span>
                            <span className="tab_side_bar_item">Báo Cáo Thu Chi</span>

                            
                        <span className="tab_side_bar_title">Hệ Thống</span>
                            <span className="tab_side_bar_item">Nhân Viên</span>
                            <span className="tab_side_bar_item">Chức Năng Truy Cập</span>
                            <span className="tab_side_bar_item">Quản Lý Chấm Công</span>
                            <span className="tab_side_bar_item">Chi Nhánh</span>
                            <span className="tab_side_bar_item">Tình Trạng</span>
                            <span className="tab_side_bar_item">Lương Nhân Viên</span>
                            <span className="tab_side_bar_item">Sao Lưu</span>
                            <span className="tab_side_bar_item">Thùng Rác</span>
                    </div>
                    <div className="tab_tick_container">
                        <div className="tab_tick">
                            {this.state.empty.map((result, i) => {
                                return(
                            <div className="column" key={"column" + i}>
                                <div className="checkbox" key={"bill" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "bill", i)} defaultChecked={data.bill[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"calendar" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "calendar", i)} defaultChecked={data.calendar[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"customers" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "customers", i)} defaultChecked={data.customers[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"spending" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "spending", i)} defaultChecked={data.spending ? data.spending[i] == "1" ? "checked" : null : null}/>
                                </div>
                            
                                <div className="checkbox" key={"goi_chup" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "goi_chup", i)} defaultChecked={data.goi_chup[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"services" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "services", i)} defaultChecked={data.services[i] == "1" ? "checked" : null}/>
                                </div>

                                <div className="checkbox" key={"total_analytics" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "total_analytics", i)} defaultChecked={data.total_analytics[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"spending_analytics" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "spending_analytics", i)} defaultChecked={data.spending_analytics ? data.spending_analytics[i] == "1" ? "checked" : null : null}/>
                                </div>
                                    
                                <div className="checkbox" key={"staff" + i}>
                                <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "staff", i)} defaultChecked={data.staff[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"permisisons" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "permisisons", i)} defaultChecked={data.permisisons[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"cham_cong" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "cham_cong", i)} defaultChecked={data.cham_cong ? data.cham_cong[i] == "1" ? "checked" : null : null}/>
                                </div>
                                <div className="checkbox" key={"branch" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "branch", i)} defaultChecked={data.branch[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"status" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "status", i)} defaultChecked={data.status[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"staff_salary" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "staff_salary", i)} defaultChecked={data.staff_salary[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"backup" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "backup", i)} defaultChecked={data.backup[i] == "1" ? "checked" : null}/>
                                </div>
                                <div className="checkbox" key={"trash" + i}>
                                    <input type="checkbox" onChange={(e) => this.props.onTickBox(e, "trash", i)} defaultChecked={data.trash[i] == "1" ? "checked" : null}/>
                                </div>
                            </div>
                       
                                )
                            }, this)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class Permission extends Component {

    constructor(props) {
        super(props);
        this.state = {
            is_modal_open: "false",
            is_edit_modal_open: "false",
            permission_data: null,
            tab_data: null,
            tab: 0
        }        

        getPermissions((data) => {
            this.setState({
                permission_data: data,
                tab_data: data[0].permisisons
            })
        });
    }

    onTabChange = (tab) => {   
        this.setState ({
            tab_data: null,
            tab: tab
        })     
        getPermissions((data) => {
            this.setState({
                tab_data: data[tab].permisisons
            })
        });
    }

    handleOpenCreateModal = () => {
        this.setState ({
            is_modal_open: "true"
        })
    }

    handleOpenEditModal = () => {
        this.setState ({
            is_edit_modal_open: "true"
        })
    }

    handleCloseModal = () => {
        this.setState ({
            is_modal_open: "false",
            is_edit_modal_open: "false"
        })
    }

    //UPDATE PERMISSION WHEN USER CHECK ON CHECKBOX
    handleTickBox = (event, element, location) => {
        let data = this.state.tab_data;
        let permission_data = this.state.permission_data;
        permission_data = permission_data[this.state.tab];
        permission_data.permisisons = data;

        let element_data = {}
        element_data[element] = ["0","0","0","0"];

        if(data[element]) {
            data[element][location] = event.target.checked ? "1" : "0";
        }else{ 
            element_data[element][location] = event.target.checked ? "1" : "0";
            data = Object.assign(data, element_data);
        }

        updatePermission(permission_data, (res) => {});
    }

    //UPDATE DATA AFTER USER EDIT
    handleUpdate = () => {
        getPermissions((data) => {
            this.setState({
                permission_data: data,
                tab: 0
            })

            this.handleCloseModal();
        });
    }

    render() {
        if(!this.state.permission_data || !this.state.tab_data) {
            return (
                <div className="view">
                    <DefaultHeaderBar  onGoBack={() => this.props.history.goBack()} title="Chức Năng Truy Cập" handleCreate={this.handleUpdateData}/>
                </div>
            )
        }
        return(
            <div className="view">
                <DefaultHeaderBar  onGoBack={() => this.props.history.goBack()} handleCreate={null} create_btn_link={null} handleSave={null} handleRemove={null} title="Chức Năng Truy Cập" handleEdit={this.state.permission_data[findWithAttr(this.state.permission_data, '_id', localStorage.getItem('chuc_vu'))].permisisons.permisisons[2] == "1" ? this.handleOpenEditModal : null} handleCreate={this.state.permission_data[findWithAttr(this.state.permission_data, '_id', localStorage.getItem('chuc_vu'))].permisisons.permisisons[1] == "1" ? this.handleOpenCreateModal :null}/>
                <TabBar onChangeTab={this.onTabChange} tab={this.state.tab} data={this.state.permission_data}/>
                <TabContentContainer data={this.state.tab_data} tab={this.state.tab} onTickBox={this.handleTickBox}/>
                <PermissionModal is_open={this.state.is_modal_open} handleCloseModal={this.handleCloseModal} onCreateNew={this.handleUpdate}/>
                <PermissionEditModal is_open={this.state.is_edit_modal_open} tab={this.state.tab} handleSave={this.handleUpdate} handleRemove={this.handleUpdate} ten_nhom={this.state.permission_data[this.state.tab].ten_nhom} _id={this.state.permission_data[this.state.tab]._id} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default Permission;