import React, {Component} from 'react';
import {getSingleBill,getBranch} from '../functions/Api';
import { numberWithCommas, findWithAttr } from '../functions/Global';
import Logo from '../styles/resources/Logo.png';
import {RiArrowLeftSLine} from 'react-icons/ri';
import {Link} from 'react-router-dom';

class BillPrint extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            branch: null
        }

        getSingleBill(this.props.match.params.id, (res) => {
            this.setState({
                data:res
            })
        });

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })
    }

    handlePrint = (e) => {
        e.target.style.display = 'none';
        this.refs.view_header.style.display = 'none';

        window.print()
    }

    render() {
        if(!this.state.data || !this.state.branch) {
            return <div></div>
        }

        let gioitinh = ["Bé Trai", "Bé Gái"]

        return(
            <div className="view bill_print">
                <h1 onClick={this.props.history.goBack} className="view_header" ref="view_header" style={{textDecoration: 'none',cursor: 'pointer',width: "100%", textAlign: "left", marginTop: "32px", marginBottom : "64px"}}><RiArrowLeftSLine className="icon"/>In Hóa Đơn</h1>
                <span style={{textAlign: "center", width: "100%", whiteSpace: "pre-wrap"}}>{findWithAttr(this.state.branch,'_id',parseInt(this.state.data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(this.state.data.chi_nhanh))].dia_chi : ''}</span>
                <h1>HÓA ĐƠN</h1>
                <span>Chi Nhánh: <span>{findWithAttr(this.state.branch,'_id',parseInt(this.state.data.chi_nhanh)) > -1 ? this.state.branch[findWithAttr(this.state.branch,'_id',parseInt(this.state.data.chi_nhanh))].ten_chi_nhanh : ''}</span></span>
                <span>Ngày Chụp: <span>{this.state.data.gio_chup} {this.state.data.ngay_chup}</span></span>
                <span>Ngày Giao Hình: <span>{this.state.data.ngay_giao_hinh}</span></span>
                <span>Tên Khách Hàng: <span>{this.state.data.ten_khach_hang}</span></span>
                <span>SĐT: <span>{this.state.data.so_dien_thoai}</span></span>
                <span>Địa Chỉ: <span>{this.state.data.dia_chi}</span></span>
                <span>Họ Tên Bé:  <span>{this.state.data.ho_ten_be}</span></span>
                <span>Ngày Sinh:  <span>{this.state.data.ngay_sinh_be}</span></span>
                <span>Giới Tính: <span>{gioitinh[this.state.data.gioi_tinh_be]} - {this.state.data.so_tuoi}</span></span>

                <hr/>

                <table>
                    <tr>
                        <th>Sản Phẩm</th>
                        <th>Đơn Giá</th>
                        <th>Số Lượng</th>
                        <th>Giảm Giá</th>
                        <th>Tổng</th>
                    </tr>
                    {this.state.data.products.map((data, i) => {
                        return(
                            <tr>
                                <td>{data.ten_goi}</td>
                                <td>{numberWithCommas(parseInt(data.gia))}</td>
                                <td>{data.so_luong}</td>
                                <td>{data.giam_gia ? data.giam_gia : 0} đ</td>
                                <td>{numberWithCommas(parseInt(data.gia) * parseInt(data.so_luong) - (data.giam_gia ? data.giam_gia : 0))} đ</td>
                            </tr>
                        )
                    })}
                </table>
                
                <hr/>

                <span>TỔNG CỘNG: <span>{numberWithCommas(parseInt(this.state.data.tong))} đ</span></span>
                <span>ĐẶT CỌC: <span>{numberWithCommas(parseInt(this.state.data.dat_coc_1))} đ</span></span>
                <span>THANH TOÁN LẦN 1: <span>{numberWithCommas(parseInt(this.state.data.thanh_toan_l1_1))} đ</span></span>
                <span>THANH TOÁN LẦN 2: <span>{numberWithCommas(parseInt(this.state.data.thanh_toan_l2_1))} đ</span></span>
                <span>THANH TOÁN LẦN 3: <span>{numberWithCommas(parseInt(this.state.data.thanh_toan_l3_1 ? this.state.data.thanh_toan_l3_1 : 0))} đ</span></span>
                <span>CÒN NỢ: <span>{numberWithCommas(parseInt(this.state.data.con_no_1))} đ</span></span>

                <button className="btn blue" onClick={this.handlePrint}>IN</button>
            </div>
        )
    }
}

export default BillPrint;