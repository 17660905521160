import React, {Component} from 'react';
import {getBill, getBranch} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {CustomersTableContainer} from '../components/Table';
import {HashSpiner} from '../components/Spiners';
import {removeVietnameseTones} from '../functions/Global';

class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bill: null,
            branch: null
        }

        getBill((res) => {
            let data = res.filter((data) => {
                return !data.deleted;
            })

            this.setState ({
              bill: data
            })
        });
  
        getBranch((res) => {
  
          this.setState({
            branch: res
          })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getBill((res) => {
                let data = res.filter((data) => {
                    return !data.deleted;
                })

                this.setState({
                    bill: data
                })            
            })
        }

        //RELOAD DATA
        getBill((res) => {
            let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.ten_khach_hang).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.so_dien_thoai).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                
                return false;
            })
  
            this.setState({
                bill: filtered_data
            })            
        })
    }

    render() {
        if(!this.state.bill && !this.state.branch) {
            return(<div className="load_view"><HashSpiner/></div>)
        }

        return(
            <div>
                <DefaultHeaderBar handleEdit={null} onSearchKeyPress={this.handleSearch} handleCreate={null} create_btn_link={null} handleSave={null} handleRemove={null} title="Khách Hàng"/>
                <CustomersTableContainer bill={this.state.bill} branch={this.state.branch}/>
            </div>
        )
    }
}

export default Customers;