import React, {Component} from 'react';
import {HeaderBar} from '../components/Header_bar';
import {BillTableContainer} from '../components/Table';
import {getBill, getPermissions} from '../functions/Api';
import {removeVietnameseTones, transDate} from '../functions/Global';

const WAIT_INTERVAL = 1000;

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bill: null,
            default_bill_data: null,
            typing: false,
            typingTimeout: 0
        }

        getBill((res) => {
            console.log(res);
          let data = res.sort((a, b) => { return new Date(transDate.toOld(b.ngay_chup)) - new Date(transDate.toOld(a.ngay_chup)) })
          
          data = data.filter((data, i) => {
            if((parseInt((data.ngay_chup.split("-")[1])) - 1) !== (new Date().getMonth())) return false;
            return !data.deleted;
          });
          
          this.setState ({
            bill: data,
            default_bill_data: data
          })
        });

        this.handleFilter = this.handleFilter.bind(this);
    }
    
    //THỰC HIỆN LỌC TỪ DROPDOWN
    handleFilter(branch, photo, support, tinh_trang, month, year, photoshop) {
        //RELOAD DATA
        getBill((res) => {
          let data = res.sort((a, b) => { return new Date(transDate.toOld(b.ngay_chup)) - new Date(transDate.toOld(a.ngay_chup)) })

          //LỌC DATA
          let filtered_data = data.filter((data, i) => {
              //LỌC THEO CHI NHÁNH
              if(branch !== "all") {
                  if(data.chi_nhanh !== branch) return false;
              }
  
              //LỌC THEO PHOTO
              if(photo !== "all") {
                  if(data.photo !== photo) return false;
              }

  
              //LỌC THEO SUPPORT
              if(support !== "all") {
                  if(data.support !== support) return false;
              }

              //LỌC THEO PHOTOSHOP
              if(photoshop !== "all") {
                if(data.photoshop !== photoshop) return false;
                }

              //LỌC THEO TÌNH TRẠNG
              if(tinh_trang !== "all") {
                  if(data.tinh_trang !== tinh_trang) return false;
              }

              //LỌC THEO THÁNG
              if(month !== "all") {
                  if((parseInt((data.ngay_chup.split("-")[1]))) !== parseInt(month)) return false;
              }

              //LỌC THEO NĂM
              if(year !== "all") {
                if((new Date(transDate.toOld(data.ngay_chup)).getFullYear()).toString() !== year.toString()) return false;
              }

              if(data.deleted) {
                  return false;
              }

              return true;
          });
  
          this.setState({
              bill: filtered_data,
              default_bill_data: filtered_data
          })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        if(this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        const self = this;

        //ĐỢI NGƯỜI DÙNG NHẬP HẾT TỪ MỚI LOAD
        this.setState({
            typing: false,
            typingTimeout: setTimeout(function () {
                self.search(search_value)
            }, 500)
        })
    }

    search = (search_value) => {
        getBill((res) => {
            //RELOAD DATA IF SEARCH VALUE IS NULL
            if(search_value === "") {
                  let data = res.sort((a, b) => { return new Date(transDate.toOld(b.ngay_chup)) - new Date(transDate.toOld(a.ngay_chup)) })
                  
                  data = data.filter((data, i) => {
                    return data.deleted == false;
                  });
                  
                  this.setState ({
                    bill: data
                  })
            }
    
            let data = res.sort((a, b) => { return new Date(transDate.toOld(b.ngay_chup)) - new Date(transDate.toOld(a.ngay_chup)) })
      
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(data.deleted) return false;
                if(removeVietnameseTones(data.ten_khach_hang).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.ho_ten_be).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.so_dien_thoai).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                return false;
            })
      
            this.setState({
                bill: filtered_data
            })
        }, this)
    }

    render() {
        if(!this.state.bill && !this.state.default_bill_data) {
            return(
                <div></div>
            )
        }

        return(
            <div>
                <HeaderBar title="Đơn Hàng" onDropdownFilterBtnClick={this.handleFilter} onSearchKeyPress={this.handleSearch}/>
                <BillTableContainer data={this.state.bill} type="0"/>
            </div>
        )
    }
}

export default Home;