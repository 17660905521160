import React, {Component} from 'react';

import {HeaderBar} from '../components/Header_bar';
import {BillTableContainer} from '../components/Table';
import {getBill} from '../functions/Api';
import {removeVietnameseTones} from '../functions/Global';

class Trash extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bill: null
        }

        getBill((res) => {
          let data = res.sort((a, b) => {
              return new Date(b.ngay_chup) - new Date(a.ngay_chup)
            })
            data = data.filter((data, i) => {
                return data.deleted;
            })
          this.setState ({
            bill: data
          })
        });

        this.handleFilter = this.handleFilter.bind(this);
    }
    
    //THỰC HIỆN LỌC TỪ DROPDOWN
    handleFilter(branch, photo, support, tinh_trang) {
        //RELOAD DATA
        getBill((res) => {
          let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})

          //LỌC DATA
          let filtered_data = data.filter((data, i) => {
              //LỌC THEO CHI NHÁNH
              if(branch !== "all") {
                  if(data.chi_nhanh !== branch) return false;
              }
  
              //LỌC THEO PHOTO
              if(photo !== "all") {
                  if(data.photo !== photo) return false;
              }
  
              //LỌC THEO SUPPORT
              if(support !== "all") {
                  if(data.support !== support) return false;
              }

              //LỌC THEO TÌNH TRẠNG
              if(tinh_trang !== "all") {
                  if(data.tinh_trang !== tinh_trang) return false;
              }

              if(!data.deleted) {
                  return false;
              }

              return true;
          });
  
          this.setState({
              bill: filtered_data
          })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getBill((res) => {
              let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})
              
              data = data.filter((data, i) => {
                return data.deleted == true;
              });
              
              this.setState ({
                bill: data
              })
            });
        }

        //RELOAD DATA
        getBill((res) => {
            let data = res.sort((a, b) => {return new Date(b.ngay_chup) - new Date(a.ngay_chup)})
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(!data.deleted) return false;
                if(removeVietnameseTones(data.ten_khach_hang).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.ho_ten_be).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                if(removeVietnameseTones(data.so_dien_thoai).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true; 
                return false;
            })
  
            this.setState({
                bill: filtered_data
            })            
        })
    }

    render() {
        if(!this.state.bill) {
            return(
                <div></div>
            )
        }

        return(
            <div>
                <HeaderBar title="Thùng Rác" onDropdownFilterBtnClick={this.handleFilter} onSearchKeyPress={this.handleSearch}/>
                <BillTableContainer data={this.state.bill} type="0" isDelete={true}/>
            </div>
        )
    }
}

export default Trash;