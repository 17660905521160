import React,{Component} from 'react';
import {removeStaff, createStaff, getSingleStaff, updateStaff} from '../functions/Api';
import {DefaultHeaderBar} from '../components/Header_bar';
import {StaffSalaryForm} from '../components/Forms';
import {Redirect} from 'react-router-dom';
import {getPermissions} from '../functions/Api';
import {salaryCalculate} from '../functions/Global';
import { findWithAttr} from '../functions/Global';

class StaffEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            redirect: false,
            perms: null
        }

        if(this.props.match.params.id !== "create_new") {
            getSingleStaff(this.props.match.params.id, (res) => {
                this.setState ({
                    data: res
                })
            });
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    handleSave = () => {
        this.refs.staff_form.handleSave();
    }

    saveStaff = (data) => {
        if(this.props.match.params.id !== "create_new") {
            let new_data = data;

            data["date_created"] = this.state.data.date_created;
            
            updateStaff(data, this.state.data._id, (res) => {
                this.setState ({
                    redirect: true
                })
            });
        }else {
            createStaff(data, (res) => {
                this.setState ({
                    redirect: true
                })
            });
        }
    }

    handleRemove = () => {
        removeStaff(this.props.match.params.id, (res) => {
            this.setState ({
                redirect: true
            })
        });
    }

    //Lọc lịch sử bảng lương
    handleFilter = (date) => {
        this.refs.staff_form.handleFilter(date);
    }

    render() {
        if(!this.state.data || !this.state.perms) {
            return(<div></div>)
        }

        if(this.state.redirect){
            return(<Redirect to="/staff_salary"/>)
        }

        return(
            <div className="view">
                <DefaultHeaderBar create_btn_link={null} onDropdownFilterSalaryBtnClick={this.handleFilter} handleSalaryFilter={this.handleFilter} handleRemove={ null }  onGoBack={() => this.props.history.goBack()} title="Lương Nhân Viên" handleSave={this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.staff_salary[2] == "1" ? this.handleSave: null}/>
                <StaffSalaryForm ref="staff_form" data={this.state.data} perms={this.state.perms} onSave={this.saveStaff} history={this.state.history}/>
            </div>
        )
    }
}

export default StaffEdit;