import React, {Component} from 'react';
import {ServicesTable} from '../components/Table';
import {ServicesHeader} from '../components/Header_bar';
import {ServicesModal, ServicesEditModal} from '../components/Modals';
import {getSingleService, getServices} from '../functions/Api';
import {removeVietnameseTones} from '../functions/Global';
import {HashSpiner} from '../components/Spiners';

class Services extends Component {
    constructor(props) {
        super(props);
        this.state =  {
            modal_is_open: "false",
            edit_modal_is_open: "false",
            edit_id: null,
            data: null,
            data_dich_vu: null
        }

        getServices((res) => {
          this.setState ({
            data_dich_vu: res
          })
        })
    }

    handleCloseModal = () => {
       this.updateData();
    }

    handleOpenModal = () => {
        this.setState({
            modal_is_open: "true"
        })
    }

    handleOpenEditModal = (id) => {
        getSingleService(id, (res) => {
            this.setState ({
                data: res,
                edit_modal_is_open: "true",
                edit_id: id
            })
        });
    }

    //THỰC HIỆN SEARCH
    handleSearch = (search_value) => {
        //RELOAD DATA IF SEARCH VALUE IS NULL
        if(search_value === "") {
            getServices((res) => {
                this.setState({
                    data_dich_vu: res
                })            
            })
        }

        //RELOAD DATA
        getServices((res) => {
            let data = res.reverse()
  
            //TÌM KHÁCH HÀNG OR TÊN BÉ OR SÔS ĐIỆN THOẠI
            let filtered_data = data.filter((data, i) => {
                if(removeVietnameseTones(data.ten_goi).toLowerCase().indexOf(removeVietnameseTones(search_value).toLowerCase()) > -1) return true;  
                
                return false;
            })
  
            this.setState({
                data_dich_vu: filtered_data
            })            
        })
    }

    updateData = () => {
        getServices((res) => {
        this.setState ({
            data_dich_vu: res,
            modal_is_open: "false",
            edit_modal_is_open: "false",
        })
      })
    }

    render() {
        if(!this.state.data_dich_vu) {
            return(<div className="load_view"><HashSpiner/></div>)
        }
        return(
            <div>
                <ServicesHeader handleOpenModal={this.handleOpenModal} onSearchKeyPress={this.handleSearch}/>
                <ServicesTable data={this.state.data_dich_vu} ref="services_table" handleOpenModal={this.handleOpenEditModal}/>
                <ServicesModal is_open={this.state.modal_is_open} handleCloseModal={this.handleCloseModal}/>
                <ServicesEditModal  data={this.state.data} is_open={this.state.edit_modal_is_open} edit_id={this.state.edit_id} handleCloseModal={this.handleCloseModal}/>
            </div>
        )
    }
}

export default Services;