import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {BsPlus, BsBoxArrowInDown} from 'react-icons/bs';
import {RiFilter3Line, RiSearchLine, RiPrinterLine, RiSaveLine, RiArrowLeftSLine, RiPencilLine, RiDeleteBinLine} from 'react-icons/ri';
import {AiOutlineMenu} from 'react-icons/ai';
import {isMobile} from 'react-device-detect';
import {MdClose, MdRestore} from 'react-icons/md';
import {API, findWithAttr} from '../functions/Global';
import {LavenDatePicker} from '../components/DatePicker';
import {getGoiChup, getPermissions, getStaff, getBranch, getStatus} from '../functions/Api';
import '../styles/components/Header_bar.scss';


class FilterDropDown extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef()

        this.state = {
            goi_chup_data: null,
            staff: null,
            branch: null,
            status: null,
            date: new Date().getFullYear() + '-' + ((new Date().getMonth() + 1) <= 9 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)),
            month: [1,2,3,4,5,6,7,8,9,10,11,12]
        }

        getGoiChup((res) => {
            this.setState ({
                goi_chup_data: res
            })
        });

        getStaff((res) => {
            this.setState({
                staff: res
            })
        })

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })

        getStatus((res) => {
            this.setState({
                status: res
            })
        });
    }

    handleFilter() {
        let branch = this.refs.chi_nhanh.value;
        let photo = this.refs.photo.value;
        let photoshop = this.refs.photoshop.value;
        let support = this.refs.support.value;
        let tinh_trang = this.refs.tinh_trang.value;
        let month = this.state.date.split("-")[1];
        let year =  this.state.date.split("-")[0];

        this.props.onFilter(branch, photo, support, tinh_trang, month, year, photoshop);
    }

    handleSalaryFilter() {
        let date = this.state.date;

        this.props.onFilter(date)
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClick)
    }
    
    componentWillUnmount() {
      // important
      document.removeEventListener('click', this.handleClick)
    }
    
    handleClick = (event) => {
        const { target } = event
        try {
            if (!this.wrapperRef.current.contains(target)) {
                this.props.onHideDropDown();
            }
        } catch (e) {
            console.log(e);
        } 
    }

    handleChange = (date) => {
        this.setState({
          date: date
        });
    }

    render() {
        if(!this.state.branch || !this.state.staff || !this.state.goi_chup_data || !this.state.status) {
            return(
                <div></div>
            )
        }
        
        const year = (new Date()).getFullYear();
        const years = Array.from(new Array(20),( val, index) => index - year);

        if(this.props.type == '1') {
            return(
                <div className="dropdown filter" ref={this.wrapperRef} style={this.props.visible ? {display: "block"} : {display: "none"}}>
                    <div className="box">
                        <span className="title">Ngày cần tìm:</span>
                        <LavenDatePicker format={'YYYY-MM'} date={this.state.date} ref="date" className="textbox calendar" onChange={this.handleChange}/>
                    </div>
    
                    <div className="box">
                        <button className="btn blue" onClick={this.handleSalaryFilter.bind(this)}>LỌC</button>
                    </div>
                </div>
            )
        }

        return(
            <div className="dropdown filter" ref={this.wrapperRef} style={this.props.visible ? {display: "block"} : {display: "none"}}>
                <div className="box">
                    <span className="title">Chi nhánh:</span>
                    <select ref="chi_nhanh">
                        <option value="all" selected="selected" >Tất cả</option>
                        {this.state.branch.map((data, i) => {
                            return (
                                <option value={data._id} key={i}>{data.ten_chi_nhanh}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="box">
                    <span className="title">Photo:</span>
                    <select ref="photo">
                        <option value="all" selected="selected" >Tất cả</option>
                        {this.state.staff.map((data, i) => {
                            return (
                                <option value={data._id} key={i}>{data.ho_ten}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="box">
                    <span className="title">Support:</span>
                    <select ref="support">
                        <option value="all" selected="selected" >Tất cả</option>
                        {this.state.staff.map((data, i) => {
                            return (
                                <option value={data._id} key={i}>{data.ho_ten}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="box">
                    <span className="title">Photoshop:</span>
                    <select ref="photoshop">
                        <option value="all" selected="selected" >Tất cả</option>
                        {this.state.staff.map((data, i) => {
                            return (
                                <option value={data._id} key={i}>{data.ho_ten}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="box">
                    <span className="title">Tình trạng:</span>
                    <select ref="tinh_trang">
                        <option value="all" selected="selected" >Tất cả</option>
                        {this.state.status.map((data, i) => {
                            return (
                                <option value={data._id} key={i}>{data.ten_tinh_trang}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="box">
                    <span className="title">Năm & Tháng:</span>
                    <LavenDatePicker format={'YYYY-MM'} date={this.state.date} ref="date" className="textbox calendar" onChange={this.handleChange}/>
                </div>

                <div className="box">
                    <button className="btn blue" onClick={this.handleFilter.bind(this)}>LỌC</button>
                </div>
            </div>
        )
    }
}

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: window.location.pathname,
            perms: null
        }
        
        getPermissions((res) => {
            this.setState({
                perms: res
            })
        });
    }

    render() {
        if(!this.state.perms) {
            return(
                <div></div>
            )
        }

        const perms = this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons;

        return(
            <div className="sidebar" style={{display: `${this.props.visible ? 'block' : 'none'}`}}>
                <button className="close_btn" onClick={this.props.onClose}><MdClose className="icon"/></button>
                <ul>

                    {perms.bill[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/">Đơn Hàng</Link></li> : null
                    }

                    {perms.calendar[0] == "1"?
                        <li onClick={this.props.onClose}><Link to="/calendar">Lịch</Link></li> : null
                    }

                    {perms.customers[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/customers">Khách Hàng</Link></li> : null
                    }

                    {perms.spending[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/spending">Chi</Link></li> : null
                    }

                    {perms.goi_chup[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/take_picture_packages">Gói Chụp</Link></li> : null
                    }

                    {perms.services[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/services">Dịch Vụ</Link></li> : null
                    }

                    {perms.total_analytics[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/total_revenue">Báo Cáo Doanh Thu</Link></li> : null
                    }

                    {perms.realtime_analytics[0] == "1" ?
                        <li onClick={this.props.onClose}><Link to="/realtime_revenue">Báo Cáo Thu Chi</Link></li> : null
                    }
                        <li onClick={this.props.onClose}><Link to="/trash">Thùng Rác</Link></li>
                        <li onClick={this.props.onClose}><Link to="/settings">Cài Đặt</Link></li>
                        <li onClick={this.props.onClose}><Link to="/logout">Đăng Xuất</Link></li>
                </ul>
            </div>
        )
    }
}

class FullSearchBox extends Component {
    render() {
        return( 
            <div className="header_bar">
                <div className="full_search_box">
                    <RiSearchLine className="icon"/>
                    <input type="text" placeholder="Tìm kiếm" onKeyUp={this.props.onKeyUp}/>
                    <MdClose className="icon close" onClick={this.props.onClose}/>
                    
                    {this.props.autocomplete_data != undefined ? 
                                    <div className="autocomplete_container">
                                        <ul>
                                            {this.props.autocomplete_data.map((data, i) => {
                                                console.log(data);
                                                return(<li>
                                                    <Link to={`/bill_edit/${data._id}`}>
                                                        {data.title}
                                                        <br/><br/>
                                                        Ngày: {data.ngay_chup}
                                                        <br/> 
                                                        Giờ chụp: {data.gio_chup}
                                                    </Link></li>)
                                            })}
                                        </ul>
                                    </div> : null}
                </div>
            </div>      
        )
    }
}

class HeaderBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter_dropdown_visible: false,
            perms: null,
            sidebar_visible: false,
            searching: false
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    //SHOW FILTER DROPDOWN
    handleShowFilterDropdown = () => {
        setTimeout(() => {
            this.setState({
                filter_dropdown_visible: true
            })
        }, 100)
    }

    //HIDE FILTER DROPDOWN
    handleHideFilterDropdown = () => {
        this.setState({
            filter_dropdown_visible: false
        })
    }

    //TÌM KIẾM
    handleSearch = (e) => {
        this.props.onSearchKeyPress(e.target.value);
    }

    render() {
        if(!this.state.perms) {
            return(
                <div></div>
            )
        }

        if(this.state.searching) {
            return <FullSearchBox onKeyUp={this.handleSearch} onClose={() => {this.setState({searching: false})}}/>
        }

        return(
            <div className="header_bar">
                <SideBar visible={this.state.sidebar_visible} onClose={() => this.setState({sidebar_visible: false})}/>
                {isMobile ? <button className="side_bar_btn" onClick={() => this.setState({sidebar_visible: true})}><AiOutlineMenu className="icon"/></button> : null}
                <h1>{this.props.title}</h1>
                <div className="right_tools_container">
                    <div className="right_tools_box">
                        {isMobile ? <a className="link_btn" href={`${API}/download_bill`} target="_download"><BsBoxArrowInDown className="icon"/></a> : <a className="link_btn" href={`${API}/download_bill`} target="_download"><BsBoxArrowInDown className="icon"/>Tải xuống</a>}
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.bill[1] == "1" ? this.props.title === "Đơn Hàng" ? (isMobile ? <Link className="link_btn" to="/bill_edit/create_new"><BsPlus className="icon"/></Link> : <Link className="link_btn" to="/bill_edit/create_new"><BsPlus className="icon"/>Tạo mới</Link>) : this.props.title === "Khách Hàng" ? "" : null : null}
                        <div className="dropdown_group">
                            {isMobile ? <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/></button> : <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/>Lọc</button>}
                            <FilterDropDown onHideDropDown={this.handleHideFilterDropdown} visible={this.state.filter_dropdown_visible} onFilter={this.props.onDropdownFilterBtnClick}/>
                        </div>
                        {this.props.onSearchKeyPress !== undefined && isMobile ?
                            <button onClick={() => {this.setState({searching: true})}}>
                                <RiSearchLine className="icon"/>
                            </button> : null }
                        <div className="search_box">
                            <RiSearchLine className="icon"/>
                            <input type="text" ref="search" placeholder="Tìm kiếm" onKeyUp={this.handleSearch}></input>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class TakePhotoPackagesHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: null,
            sidebar_visible: false,
            searching: false
        } 

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    render() {
        if(!this.state.perms) {
            return(
                <div></div>
            )
        }

        if(this.state.searching) {
            return <FullSearchBox onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)} onClose={() => {this.setState({searching: false})}}/>
        }

        return(
            <div className="header_bar">
                <SideBar visible={this.state.sidebar_visible} onClose={() => this.setState({sidebar_visible: false})}/>
                {isMobile ? <button className="side_bar_btn" onClick={() => this.setState({sidebar_visible: true})}><AiOutlineMenu className="icon"/></button> : null}
                <h1>Gói Chụp</h1>
                <div className="right_tools_container">
                    <div className="right_tools_box">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.goi_chup[1] == "1" ? <button onClick={this.props.handleOpenModal}><BsPlus className="icon"/>Thêm mới</button> : null }
                        
                        {this.props.onSearchKeyPress !== undefined && isMobile ?
                            <button onClick={() => {this.setState({searching: true})}}>
                                <RiSearchLine className="icon"/>
                            </button> : null }
                        <div className="search_box">
                            <RiSearchLine className="icon"/>
                            <input type="text" ref="search" placeholder="Tìm kiếm" onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)}></input>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class ServicesHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: null,
            sidebar_visible: false,
            searching: false
        } 

        getPermissions((res) => {
            this.setState({
                perms: res
            })
        })
    }

    render() {
        if(!this.state.perms) {
            return(
                <div></div>
            )
        }

        if(this.state.searching) {
            return <FullSearchBox onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)} onClose={() => {this.setState({searching: false})}}/>
        }

        return(
            <div className="header_bar">
                <SideBar visible={this.state.sidebar_visible} onClose={() => this.setState({sidebar_visible: false})}/>
                {isMobile ? <button className="side_bar_btn" onClick={() => this.setState({sidebar_visible: true})}><AiOutlineMenu className="icon"/></button> : null}
                <h1>Dịch Vụ</h1>
                <div className="right_tools_container">
                    <div className="right_tools_box">
                        {this.state.perms[findWithAttr(this.state.perms, '_id', localStorage.getItem('chuc_vu'))].permisisons.services[1] == "1" ? <button onClick={this.props.handleOpenModal}><BsPlus className="icon"/>Thêm mới</button> : null}
                                                
                        {this.props.onSearchKeyPress !== undefined && isMobile ?
                            <button onClick={() => {this.setState({searching: true})}}>
                                <RiSearchLine className="icon"/>
                            </button> : null }

                        <div className="search_box">
                            <RiSearchLine className="icon"/>
                            <input type="text" ref="search" placeholder="Tìm kiếm" onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)}></input>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class BillEditHeader extends Component {

    render() {
        return(
            <div className="header_bar">
                {isMobile ? <button className="side_bar_btn"><AiOutlineMenu className="icon"/></button> : null}
                <h1 className="back" onClick={this.props.onGoBack}><RiArrowLeftSLine className="icon"/>Thông Tin Đơn Hàng</h1>
                <div className="right_tools_container">
                    <div className="right_tools_box">
                        <button><RiPrinterLine className="icon"/>{isMobile ? null : 'In hóa đơn'}</button>
                        <button onClick={this.props.onSave}><RiSaveLine className="icon"/>{isMobile ? null : 'Lưu'}</button>
                        <button><BsPlus className="icon"/>{isMobile ? null : 'Thêm sản phẩm'}</button>
                    </div>
                </div>
            </div>
        )
    }
}

class SettingsHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar_visible: false
        }
    }
    
    render() {
        return(
            <div className="header_bar">
                <SideBar visible={this.state.sidebar_visible} onClose={() => this.setState({sidebar_visible: false})}/>
                {isMobile ? <button className="side_bar_btn" onClick={() => this.setState({sidebar_visible: true})}><AiOutlineMenu className="icon"/></button> : null}
                <h1>Cài Đặt</h1>
                <div className="right_tools_container">
                </div>
            </div>
        )
    }
}

class DefaultHeaderBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar_visible: false,
            filter_dropdown_visible: false,
            branch: null,
            searching: false
        }

        getBranch((res) => {
            this.setState({
                branch: res
            })
        })
    }

    //SHOW FILTER DROPDOWN
    handleShowFilterDropdown = () => {
        setTimeout(() => {
            this.setState({
                filter_dropdown_visible: true
            })
        }, 100)
    }

    //HIDE FILTER DROPDOWN
    handleHideFilterDropdown = () => {
        this.setState({
            filter_dropdown_visible: false
        })
    }

    render() {
        if(!this.state.branch) {
            return <div></div>
        }

        if(this.state.searching) {
            return <FullSearchBox autocomplete_data={this.props.autocomplete_data} onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)} onClose={() => {this.setState({searching: false})}}/>
        }

        return(
            <div className="header_bar">
                <SideBar visible={this.state.sidebar_visible} onClose={() => this.setState({sidebar_visible: false})}/>
                {isMobile ? (this.props.back_link !== undefined ? <Link onClick={this.props.onGoBack} className="side_bar_btn"><RiArrowLeftSLine className="icon"/></Link> : <button className="side_bar_btn" onClick={() => this.setState({sidebar_visible: true})}><AiOutlineMenu className="icon"/></button>) : null}
                {this.props.onGoBack !== undefined ? 
                <h1 className="back" onClick={this.props.onGoBack}><RiArrowLeftSLine className="icon"/>{this.props.title}</h1> : 
                    <h1>{this.props.title}</h1>
                }
                
                <div className="right_tools_container">
                    <div className="right_tools_box">
                        <h4 className="custom_title" style={{opacity: .55, fontSize: 13, fontWeight: 'bold', marginRight: 12}}>{this.props.customTitle}</h4>
                        {this.props.handleSalaryFilter !== undefined ?      
                        <div className="dropdown_group">
                            {isMobile ? <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/></button> : <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/>Lọc</button>}
                            <FilterDropDown type="1" onHideDropDown={this.handleHideFilterDropdown} visible={this.state.filter_dropdown_visible} onFilter={this.props.onDropdownFilterSalaryBtnClick}/>
                        </div>:null}
                        {this.props.handleEdit !== null && this.props.handleEdit !== undefined ? <button onClick={this.props.handleEdit}><RiPencilLine className="icon"/>{isMobile ? null : "Chỉnh sửa" }</button> : null}
                        {this.props.create_btn_link !== null && this.props.create_btn_link !== undefined? <Link className="link_btn" to={this.props.create_btn_link}><BsPlus className="icon"/>{isMobile ? null : "Tạo mới"}</Link> : null}
                        {this.props.handleCreate !== null && this.props.handleCreate !== undefined? <button onClick={this.props.handleCreate}><BsPlus className="icon"/>{isMobile ? null : "Tạo mới"}</button> : null}
                        {this.props.onDropdownFilterBtnClick !== undefined ? <div className="dropdown_group">
                            {isMobile ? <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/></button> : <button onClick={this.handleShowFilterDropdown}><RiFilter3Line className="icon"/>Lọc</button>}
                            <FilterDropDown onHideDropDown={this.handleHideFilterDropdown} visible={this.state.filter_dropdown_visible} onFilter={this.props.onDropdownFilterBtnClick}/>
                        </div> : null }
                        {this.props.handleAddProduct !== undefined && this.props.handleAddProduct !== null ?<button onClick={this.props.handleAddProduct}><BsPlus className="icon"/>{isMobile ? null : "Thêm sản phẩm"}</button> : null}
                        {this.props.handlePrintBill !== undefined ? <button onClick={this.props.handlePrintBill}><RiPrinterLine className="icon"/>{isMobile ? null : "In hóa đơn"}</button> : null}
                        {this.props.handleSave !== null && this.props.handleSave !== undefined? <button onClick={this.props.handleSave}><RiSaveLine className="icon"/>{isMobile ? null : "Lưu"}</button> : null}
                        {this.props.handleRestore !== null && this.props.handleRestore !== undefined ? <button onClick={this.props.handleRestore}><MdRestore className="icon"/>{isMobile? null : "Khôi phục đơn hàng"}</button> : null}
                        {this.props.handleRemove !== null && this.props.handleRemove !== undefined? <button onClick={this.props.handleRemove}><RiDeleteBinLine className="icon"/>{isMobile ? null : "Xóa"}</button> : null}
                        {this.props.handleCalendarFilter !== undefined ?
                            <select ref="branch" onChange={this.props.handleCalendarFilter}>
                                <option value="" disabled selected>Chi nhánh</option>
                                <option value="all">Tất cả</option>
                                {this.state.branch.map((data, i) => {
                                    return(
                                        <option value={data.ten_chi_nhanh}>{data.ten_chi_nhanh}</option>
                                    )
                                })}
                            </select>: null}
                            {this.props.onSearchKeyPress !== undefined && isMobile ?
                            <button onClick={() => {this.setState({searching: true})}}>
                                <RiSearchLine className="icon"/>
                            </button> : null }
                        {this.props.onSearchKeyPress !== undefined ?                           
                            <div className="search_box">
                                <RiSearchLine className="icon"/>
                                <input type="text" ref="search" placeholder="Tìm kiếm" onKeyUp={(e) => this.props.onSearchKeyPress(e.target.value)}></input>
                            
                                {this.props.autocomplete_data != undefined ? 
                                    <div className="autocomplete_container">
                                        <ul>
                                            {this.props.autocomplete_data.map((data, i) => {
                                                console.log(data);
                                                return(<li>
                                                    <Link to={`/bill_edit/${data._id}`}>
                                                        {data.title}
                                                        <br/><br/>
                                                        Ngày: {data.ngay_chup}
                                                        <br/> 
                                                        Giờ chụp: {data.gio_chup}
                                                    </Link></li>)
                                            })}
                                        </ul>
                                    </div> : null}

                            </div>       : null}
                    </div>
                </div>
            </div>
        )
    }
}

export {
    HeaderBar,
    BillEditHeader,
    TakePhotoPackagesHeader,
    ServicesHeader,
    SettingsHeader,
    DefaultHeaderBar
}