import React, {Component} from 'react';
import '../styles/components/Cards.scss';
import {numberWithCommas,salaryLoad, salaryCalculate, getAge} from '../functions/Global';
import {getSingleStaffFromEmail, getPermissions} from '../functions/Api';

class SalaryTableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            perms: null,
            chuc_vu: null,
            history: null
        }

        getPermissions((res) => {
            this.setState({
                perms: res
            })

            getSingleStaffFromEmail(this.props.data.email, (res) => {
                this.setState({
                    chuc_vu: this.state.perms[res.chuc_vu].ten_nhom
                })
            })
        })
    }
    render() {
        if(!this.props.data || !this.state.perms || !this.state.chuc_vu || !this.props.history) {
            return <div></div>
        }

        const data = this.props.history;
        let chuc_vu = this.state.chuc_vu;

        let date_created = data.date_created.split("T")[0];
        date_created = date_created.split("-");
        date_created  ='Tháng ' + date_created[1] + ' năm ' + date_created[0];

        return(
            <div className="card salary">
                <h1>{date_created}</h1>
                <span><b>Họ tên:</b> <span>{data.ho_ten}</span></span>
                <span><b>Chức vụ:</b> <span>{chuc_vu}</span></span>
                <span><b>Lương cơ bản:</b> <span>{numberWithCommas(data.luong_co_ban)} đ</span></span>
                <span><b>Ngày công:</b> <span>{data.ngay_cong} ngày</span></span>
                <span><b>Lương tháng:</b> <span>{numberWithCommas(data.luong_thang)} đ</span></span>
                <span><b>Thưởng doanh thu tư vấn ( Gói chụp ):</b> <span>{data.phan_tram_thuong_goi_chup_tu_van} %</span></span>
                <span><b>Thưởng doanh thu tư vấn ( Dịch vụ ):</b> <span>{data.phan_tram_thuong_dich_vu_tu_van} %</span></span>
                <span><b>Thưởng doanh thu photo ( Gói chụp ):</b> <span>{data.phan_tram_thuong_goi_chup_photo} %</span></span>
                <span><b>Thưởng doanh thu:</b> <span>{numberWithCommas(data.total_photo)} đ</span></span>
                <span><b>Thưởng dịch vụ:</b> <span>{numberWithCommas(data.total_service)} đ</span></span>
                <span><b>Tổng thưởng:</b> <span>{numberWithCommas(data.total)} đ</span></span>
                <hr/>
                <span><b>Thực lãnh:</b> <span>{numberWithCommas(data.thuc_lanh)}  đ</span></span>
            </div>
        )
    }
}

class AnalyticsCard extends Component {
    render() {
        return(
            <div className="card analytics" style={this.props.onClick ? {cursor: "pointer"} : null} onClick={this.props.onClick}>
                <div className={"value_container " + this.props.type}>
                    <p>{this.props.type === "da_thu" ? "Đã thu" : this.props.type === "tong" ? "Tổng" : (this.props.type==="da_chi" ? "Đã chi" : this.props.type==="con_giu" ? "Còn giữ" : "Còn nợ")}</p>
                    <h1>{this.props.value} đ</h1>
                    {this.props.type === "da_thu" ? <p>CK: {this.props.chuyen_khoan_num} đ</p>: null}
                    {this.props.type === "da_thu" ? <p>TM: {this.props.tien_mat_num} đ</p>: null}
                    {this.props.type === "da_chi" ? <p>CK: {this.props.chuyen_khoan_num} đ</p>: null}
                    {this.props.type === "da_chi" ? <p>TM: {this.props.tien_mat_num} đ</p>: null}
                    {this.props.type === "con_giu" ? <p>CK: {this.props.chuyen_khoan_num} đ</p>: null}
                    {this.props.type === "con_giu" ? <p>TM: {this.props.tien_mat_num} đ</p>: null}
                </div>
            </div>
        )
    }
}

class ProductCard extends Component {
    render() {
        if(this.props.type === "0") {
            return(
                <div className="card product add_new">
                    <a>Thêm mới</a>
                </div>
            )
        }else {
            return(
                <div className="card product">
                    <p className="title">{this.props.title}</p>
                    <p className="sub_title">{this.props.sub_title}</p>
                    <div className="card_image" style={{background: "url(" + this.props.image + ") no-repeat center center / cover"}}></div>
                </div>
            )
        }
    }
}

export {
    ProductCard,
    AnalyticsCard,
    SalaryTableCard 
}